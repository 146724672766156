import React from "react";
import moment from "moment";
import { toFixedUtil } from "../../component/utils";
import CopyData from "../../component/Reusable/CopyData";

export function MissedColumn() {
  return [
    {
      name: "S.No1",
      sortable: true,
      selector: (row, index) => row.index,
      cell: (row, index) => <td>{index + 1}</td>,
    },
    {
      name: "Tnx.Id",
      sortable: true,
      selector: (row, index) => row.stake_trans_id,
      cell: (row, index) => {
        return (
          <CopyData
            data={row.stake_trans_id}
            index={index}
            formatValue={true} />
        );
      },
    },
    {
      name: "Level",
      selector: (row) => row.level,
      sortable: true,
    },

    {
      name: "Rewards",
      selector: (row) => toFixedUtil(row.levelbonusamount, 2),

      sortable: true,
    },
    {
      name: "Percentage",
      sortable: true,
      selector: (row) => row.Bonuspercentage,
    },
    {
      name: "Team Amount",
      sortable: true,
      selector: (row) => row.team_amount,
    },
    {
      name: "Level Eligible Amount",
      sortable: true,
      selector: (row) => row.level_eliglible_amount,
    },
    {
      name: "Staker Name",
      sortable: true,
      selector: (row) => row.stakerusername,
      cell: (row, index) => {
        return (
          <CopyData
            data={row.stakerusername}
            index={index}
            formatValue={false} />
        );
      },
    },
    {
      name: "Status",
      selector: (row, index) => (
        <td className="text-danger" style={{textTransform:"capitalize"}}>{row.eligible_status?.replace("_"," ")}</td>
      ),
      sortable: true,
    },
    { 
      name: "Date & Time",
      sortable: true,
      selector: (row, index) => row.created_at,

      cell: (row, index) => moment(row.created_at).format("YYYY-MM-DD hh:mm:ss A"),
    },
  ];
}
