import React, { useEffect, useState } from "react";
import Footer from "../../component/footer";
import Header from "../../component/header";
import Helper from "../../Helper/Helper";
import { ButtonLoader, ScreenLoader } from "../../component/Loaders";
import metadata from "../../metaData/metadata";
import { Helmet } from "react-helmet";
import NewHeader from "../../component/header/NewHeader";
import { useNavigate } from "react-router-dom";
const Incubator = () => {
  const [response, setResponse] = useState({ plan_content: {} });
  const [Loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const navigate = useNavigate();
  const isLogin = localStorage.getItem("tip");
  useEffect(() => {
    if (isLogin) {
      getIncubatorInfo();
    } else {
      navigate("/oauth/login");
    }
  }, []);

  const getIncubatorInfo = () => {
    setLoading(true);
    Helper.PostData(
      "wallet",
      "incubator/plan_subscribe_balance",
      {
        user_id: localStorage.getItem("tip"),
      },
      false
    ).then((res) => {
      if (res.status) {
        setResponse(res.data);
        setLoading(false);
      }
    });
  };

  const handlePurchase = () => {
    if (+response.plan_price <= +response.available_balance) {
      setBtnLoading(true);
      Helper.PostData(
        "wallet",
        "incubator/plan_subscribe",
        {
          user_id: localStorage.getItem("tip"),
          currency_symbol: response.plan_currency,
          subscribe_amount: response.plan_price,
        },
        true
      )
        .then((res) => {
          if (res.status) {
            getIncubatorInfo();
          }
        })
        .finally(() => setBtnLoading(false));
    } else {
      Helper.toasts(`Insufficient ${response.plan_currency} Balance`);
    }
  };

  return (
    <>
      <NewHeader />
      <Helmet>
        <title>{metadata.incubator.title}</title>
        <meta name="description" content={metadata.incubator.description} />
        <meta name="keywords" content={metadata.incubator.keywords} />
      </Helmet>
      <ScreenLoader loading={Loading}>
        <div className="leftrightCir">
          <section class="pt-5 mt-5">
            <div className="pt-5 pb-5">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div class="mycard">
                      <div class="secTit mb-0 text-center">Incubator</div>
                      <div class="d-flex align-items-center">
                        <div class="col-lg-6">
                          <div class="fs-18 fw-600 mb-3">Purchase</div>
                        </div>
                        <div class="col-lg-6 text-center text-lg-right">
                          <div class="fs-16 fw-500 mb-3">
                            Balance: {response.available_balance}{" "}
                            {response.plan_currency}
                          </div>
                        </div>
                      </div>
                      <div class="cmspara">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: response.plan_content.content,
                          }}
                        ></p>
                      </div>
                      <div class="row justify-content-center">
                        <div class="col-lg-6">
                          {(() => {
                            if (response.user_subscribe_status == 1) {
                              return (
                                <button
                                  type="button"
                                  class="btn btn-block ng-star-inserted"
                                  style={{
                                    backgroundColor: "#777787",
                                    cursor: "not-allowed",
                                  }}
                                  disabled
                                >
                                  Purchase <i class="bi bi-cart-fill"></i> (
                                  {response.plan_price} {response.plan_currency}
                                  )
                                </button>
                              );
                            } else if (btnLoading) {
                              return (
                                <button
                                  type="button"
                                  class="btn btn-primary btn-block ng-star-inserted"
                                >
                                  <ButtonLoader />
                                </button>
                              );
                            } else if (response.user_subscribe_status == 0) {
                              return (
                                <button
                                  type="button"
                                  class="btn  btn-primary btn-block ng-star-inserted"
                                  onClick={() => handlePurchase()}
                                >
                                  Purchase <i class="bi bi-cart-fill"></i> (
                                  {response.plan_price} {response.plan_currency}
                                  )
                                </button>
                              );
                            }
                          })()}
                        </div>
                      </div>
                      <br />
                      {response.user_subscribe_status == 1 && (
                        <div class="row justify-content-center ng-star-inserted">
                          <span class="text-success">
                            * Note : <span>{response.note_msg}</span>
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </ScreenLoader>
      <Footer />
    </>
  );
};

export default Incubator;
