import React, { useEffect, useState } from "react";
import searchicon from "../../assets/images/search-icon.svg";
import DataTable from "react-data-table-component";
import moment from "moment";
import Helper from "../../Helper/Helper";
import { dataTableConfigs } from "../../configs/dataTableConfigs";
import { SpinnerLoader, TableLoader } from "../../component/Loaders";
import NoDataComponent from "../../component/Reusable/no-data-component";
import "../Incubator/incubator.scss";
import SearchReuse from "../../component/Reusable/searchReuse";
import CopyData2 from "../../component/Reusable/CopyData 2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faEdit } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import CloseBtn from "../../component/Reusable/CloseBtn";
import { Tooltip, Zoom } from "@mui/material";

const InvestorMeeting = () => {
  const initialPageData = { pageNo: 0, pageSize: 10 };
  const {formatAddress} = Helper;
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
  });
  const {
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
    control: control1,
    reset: reset1,
    register: register1,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
  });
  const [incubatorData, setIncubatorHistory] = useState({
    data: [],
    count: 0,
  });
  const fromMinDate = () => {
    let date = new Date();
    return date;
  };
  const [type, setType] = useState("1");
  const [IsRemark, setIsRemark] = useState(false);
  const [Load, setLoad] = useState(false);
  const [rowInfo, setRowInfo] = useState("");
  const toggleRemark = (row) => {
    setRowInfo(row.id);
    setIsRemark(!IsRemark);
  };
  const toggleRemarkClose = (row) => {
    setIsRemark(false);
  };
  const [Loading, setLoading] = useState(false);
  const [filters, setFilter] = useState({
    user_id: localStorage.getItem("tip"),
    search_by: "",
    status: "upcomming",
    ...initialPageData,
  });
  const [isMeet, setIsMeet] = useState(false);
  const toggleMeet = (row) => {
    setRowInfo(row);
    setIsMeet(!isMeet);
    reset1();
  };
  const toggleMeetclose = () => {
    setMeetLoad(false);
    setIsMeet(false);
    reset1();
  };
  const [meetLoad, setMeetLoad] = useState(false);
  const HandleMeetSchedule = (data) => {
    setMeetLoad(true);
    Helper.PostData("inves", "crm/scheduleMeet", {
      user_id: localStorage.getItem("tip"),
      mail_id: rowInfo.email,
      type: data.type,
      start_date_time: moment(data.start_date).format("YYYY-MM-DD HH:mm:ss"),
      location: data.location,
      address: data.address,
      remarks: data.remarks,
    })
      .then((res) => {
        if (res.status) {
          setIsMeet(!isMeet);
          setMeetLoad(false);
          getStateHistory();
        }
      })
      .finally(() => {
        setMeetLoad(false);
      });
  };

  useEffect(() => {
    getStateHistory();
  }, [filters]);

  const getStateHistory = () => {
    setLoading(true);
    Helper.PostData("inves", "crm/investorsMeetingList", filters, false)
      .then((res) => {
        if (res.status) {
          setIncubatorHistory({
            data: res.data.results,
            count: res.data.count,
          });
        } else {
          setIncubatorHistory({
            data: [],
            count: 0,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    {
      name: "S.No",
      sortable: true,
      selector: (row, index) => row.index,
      cell: (row, index) => <td>{index + 1}</td>,
    },

    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      cell: (row) => (
        <td>
          <CopyData2 data={row.email} />
        </td>
      ),
      sortable: true,
    },
    {
      name: "Type",
      sortable: true,
      selector: (row) => row.status,
      cell: (row) => {
        if (+row.meetingType == 0) {
          return <div className=" badge text-warn">Offline</div>;
        } else {
          return <div className="badge bg-success">Online</div>;
        }
      },
    },
    {
      name: "Status",
      sortable: true,
      selector: (row) => row.status,
      cell: (row) => {
        if (row.meetingStatus == 0) {
          return <div className=" badge text-warn">Upcoming</div>;
        } else {
          return <div className="badge bg-success">Completed</div>;
        }
      },
    },
    {
      name: "Meeting Time",
      sortable: true,
      selector: (row, index) => row.meet_start_time,

      cell: (row, index) => (
        <td>{moment(row.meet_start_time).format("YYYY-MM-DD hh:mm:ss A")}</td>
      ),
    },
  ];

  const columnsCompleted = [
    {
      name: "S.No",
      sortable: true,
      selector: (row, index) => row.index,
      cell: (row, index) => <td>{index + 1}</td>,
    },

    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      cell: (row) => (
        <td>
          <CopyData2 data={row.email} />
        </td>
      ),
      sortable: true,
    },
    {
      name: "Type",
      sortable: true,
      selector: (row) => row.status,
      cell: (row) => {
        if (+row.meetingType == 0) {
          return <div className=" badge text-warn">Offline</div>;
        } else {
          return <div className="badge bg-success">Online</div>;
        }
      },
    },
    {
      name: "Status",
      sortable: true,
      selector: (row) => row.status,
      cell: (row) => {
        if (row.meetingStatus == 0) {
          return <div className=" badge text-warn">Upcoming</div>;
        } else {
          return <div className="badge bg-success">Completed</div>;
        }
      },
    },
    {
      name: "Meeting Time",
      sortable: true,
      selector: (row, index) => row.meet_start_time,
      cell: (row, index) => (
        <td>{moment(row.meet_start_time).format("YYYY-MM-DD hh:mm:ss A")}</td>
      ),
    },
    {
      name: "Remarks",
      sortable: true,
      cell: (row, index) => (
        <td>
          {row.aftrRemarks ? (
            <Tooltip
              id="toot-tip"
              TransitionComponent={Zoom}
              style={{
                opacity: 1,
              }}
              title={row.aftrRemarks}
              placement="top"
              arrow
            >
              <span
                style={{
                  color: "white !important",
                  cursor: "pointer",
                  fontSize: "13px",
                }}
              >
                {formatAddress(row.aftrRemarks)}
              </span>
            </Tooltip>
          ) : (
            <FontAwesomeIcon
              icon={faEdit}
              className="cursor-pointer"
              onClick={() => toggleRemark(row)}
            />
          )}
        </td>
      ),
    },
    {
      name: "Meeting ReSchedule",
      sortable: true,
      cell: (row, index) => (
        <td>
          <FontAwesomeIcon
            icon={faClock}
            className="cursor-pointer"
            onClick={() => toggleMeet(row)}
          />
        </td>
      ),
    },
  ];
  const AddRemark = (data) => {
    setLoad(true);
    Helper.PostData(
      "inves",
      "crm/afterMeetRemark",
      { id: rowInfo, userId: localStorage.getItem("tip"), ...data },
      true
    )
      .then((res) => {
        if (res.status) {
          setLoad(false);
          setIsRemark(false);
          getStateHistory();
        }
      })
      .finally(() => {
        setLoad(false);
      });
  };

  return (
    <div>
      <div className="trans-body">
        <div className="trans-TabCnt">
          <div className="stakingbonus-tabcnt">
            <div className="bonus-Head">
              <h5>Investor Meeting List</h5>
            </div>
            <div className="bonus-Body">
              <div className="tbl-showentriessearch jus_start">
                <div className="showentries-RightCnt">
                  <div className="inr-searchcnt">
                    <div className="row">
                      <div className="col-lg-5">
                        <select
                          className="form-control"
                          onChange={(e) =>
                            setFilter((prev) => ({
                              ...prev,
                              status: e.target.value,
                            }))
                          }
                        >
                          <option value="upcomming">Upcoming</option>
                          <option value="completed">Completed</option>
                        </select>
                      </div>
                      <SearchReuse
                        className="col-lg-6"
                        setFilters={setFilter}
                        initialPageData={initialPageData}
                        placeholder={"Search By Email"}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="cmntbl">
                <div className="table-responsive">
                  {filters.status == "upcomming" ? (
                    <DataTable
                      columns={columns}
                      className="text-center"
                      data={incubatorData.data}
                      paginationTotalRows={incubatorData.count}
                      customStyles={dataTableConfigs}
                      pagination={false}
                      paginationServer
                      onChangePage={(pageNo) => {
                        setFilter((prev) => ({ ...prev, pageNo: pageNo - 1 }));
                      }}
                      progressPending={Loading}
                      progressComponent={<TableLoader />}
                      noDataComponent={<NoDataComponent />}
                      onChangeRowsPerPage={(pageSize) => {
                        setFilter((prev) => ({ ...prev, pageSize }));
                      }}
                      persistTableHead={true}
                      paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    />
                  ) : (
                    <DataTable
                      columns={columnsCompleted}
                      className="text-center"
                      data={incubatorData.data}
                      paginationTotalRows={incubatorData.count}
                      customStyles={dataTableConfigs}
                      pagination={false}
                      paginationServer
                      onChangePage={(pageNo) => {
                        setFilter((prev) => ({ ...prev, pageNo: pageNo - 1 }));
                      }}
                      progressPending={Loading}
                      progressComponent={<TableLoader />}
                      noDataComponent={<NoDataComponent />}
                      onChangeRowsPerPage={(pageSize) => {
                        setFilter((prev) => ({ ...prev, pageSize }));
                      }}
                      persistTableHead={true}
                      paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={IsRemark}
        toggle={toggleRemark}
        className="modal-md modal-dialog-centered mymodal"
        size="md"
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader
          toggle={toggleRemarkClose}
          close={<CloseBtn onClick={() => toggleRemarkClose()} />}
        >
          Meeting Remark
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(AddRemark)} autoComplete="off">
            <div class="row">
              <div className="col-12">
                <div class="form-group formInputs walletInput">
                  <label class="col-form-label">Remark</label>
                  <textarea
                    class="form-control"
                    name="remarks"
                    placeholder="Enter Remarks"
                    {...register("remarks", {
                      required: "* Remark is required",
                    })}
                  />
                  {errors.remarks && (
                    <p className="text-danger">{errors.remarks.message}</p>
                  )}
                </div>
              </div>
            </div>
            <div class="text-center mb-2 mt-3">
              {Load ? (
                <button
                  class="btn btn-primary px-4"
                  type="button"
                  disabled={Load}
                >
                  <SpinnerLoader />
                </button>
              ) : (
                <button class="btn btn-primary px-4" type="submit">
                  Confirm
                </button>
              )}
            </div>
          </form>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={isMeet}
        toggle={toggleMeet}
        className="modal-md modal-dialog-centered mymodal"
        size="md"
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader
          toggle={toggleMeetclose}
          close={<CloseBtn onClick={() => toggleMeetclose()} />}
        >
          Meeting Schedule
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit1(HandleMeetSchedule)} autoComplete="off">
            <div class="row">
              <div className="col-12">
                <div class="form-group formInputs walletInput">
                  <label class="col-form-label">Email</label>
                  <input
                    class="form-control"
                    name="email"
                    placeholder="Email Address"
                    defaultValue={rowInfo?.email}
                    disabled={true}
                  />
                </div>

                <div class="wallet-in-ser formInputs walletInput">
                  <label>Meeting Time</label>
                  <div class="input-login-1  ml-lg-2">
                    <Controller
                      name="start_date"
                      control={control1}
                      rules={{ required: true }}
                      render={({ field: { onChange, onBlur, value } }) => {
                        const selectedStartDate = value
                          ? new Date(value)
                          : null;

                        return (
                          <DatePicker
                            className="form-control"
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={selectedStartDate}
                            minDate={fromMinDate()}
                            dropdownMode="select"
                            peekNextMonth
                            showTimeSelect={true}
                            dateFormat="dd/MM/yyyy HH:mm:ss"
                            placeholderText="DD/MM/YYYY HH:MM:SS"
                          />
                        );
                      }}
                    />
                    {errors1.start_date && (
                      <p>
                        {errors1.start_date.type === "required" ? (
                          <p className="text-danger">
                            * Start Date is required
                          </p>
                        ) : (
                          <p className="text-danger">
                            {errors1.start_date.message}
                          </p>
                        )}
                      </p>
                    )}
                  </div>
                </div>
                <div class="form-group formInputs walletInput">
                  <label class="col-form-label">Type</label>
                  <select
                    class="form-control"
                    name="type"
                    {...register1("type", {
                      required: "* Type is required",
                    })}
                    onChange={({ target: { value } }) => {
                      setType(value);
                    }}
                  >
                    <option hidden value="">
                      Select Type
                    </option>
                    <option value={1}>Online</option>
                    <option value={0}>Offline</option>
                  </select>
                  {errors1.type && (
                    <p className="text-danger">{errors1.type.message}</p>
                  )}
                </div>
                {+type == 0 && (
                  <>
                    <div class="form-group formInputs walletInput">
                      <label class="col-form-label">Location</label>
                      <input
                        class="form-control"
                        name="location"
                        placeholder="Enter Location"
                        {...register1("location", {
                          required: "* Location is required",
                        })}
                      />
                      {errors1.location && (
                        <p className="text-danger">
                          {errors1.location.message}
                        </p>
                      )}
                    </div>
                    <div class="form-group formInputs walletInput">
                      <label class="col-form-label">Address</label>
                      <input
                        class="form-control"
                        name="address"
                        placeholder="Enter Address"
                        {...register1("address", {
                          required: "* Address is required",
                        })}
                      />
                      {errors1.address && (
                        <p className="text-danger">{errors1.address.message}</p>
                      )}
                    </div>
                  </>
                )}
                <div class="form-group formInputs walletInput">
                  <label class="col-form-label">Remark</label>
                  <textarea
                    class="form-control"
                    name="remarks"
                    placeholder="Enter Remarks"
                    {...register1("remarks", {
                      required: "* Remark is required",
                    })}
                  />
                  {errors1.remarks && (
                    <p className="text-danger">{errors1.remarks.message}</p>
                  )}
                </div>
              </div>
              {/* <div className={"col-12"}>
                <div class="wallet-in-ser formInputs walletInput">
                  <label>End Date</label>
                  <div class="input-login-1 ml-lg-2">
                    <Controller
                      name="end_date"
                      control={control1}
                      rules={{ required: true }}
                      render={({ field: { onChange, onBlur, value } }) => {
                        const selectedDate = value ? new Date(value) : null;

                        return (
                          <DatePicker
                            className="form-control"
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={selectedDate}
                            maxDate={new Date()}
                            dropdownMode="select"
                            peekNextMonth
                            dateFormat="dd/MM/yyyy HH:mm:ss"
                            placeholderText="DD/MM/YYYY HH:MM:SS"
                            showTimeSelect={true}
                          />
                        );
                      }}
                    />
                    {errors1.end_date && (
                      <p>
                        {errors1.end_date.type === "required" ? (
                          <p className="text-danger">* End Date is required</p>
                        ) : (
                          <p className="text-danger">
                            {errors1.end_date.message}
                          </p>
                        )}
                      </p>
                    )}
                  </div>
                </div>
              </div> */}
            </div>
            <div class="text-center mb-2 mt-3">
              {meetLoad ? (
                <button
                  class="btn btn-primary px-4"
                  type="button"
                  disabled={meetLoad}
                >
                  <SpinnerLoader />
                </button>
              ) : (
                <button class="btn btn-primary px-4" type="submit">
                  Confirm
                </button>
              )}
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default InvestorMeeting;
