import {
  faCarCrash,
  faDownload,
  faEdit,
  faEye,
  faInfo,
  faInfoCircle,
  faPen,
  faPlus,
  faTrash,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useForm } from "react-hook-form";
import { Button, Form, Modal, ModalBody, ModalHeader } from "reactstrap";
import Helper from "../../../Helper/Helper";
import { SpinnerLoader, TableLoader } from "../../../component/Loaders";
import CloseBtn from "../../../component/Reusable/CloseBtn";
import NoDataComponent from "../../../component/Reusable/no-data-component";
import { dataTableConfigs } from "../../../configs/dataTableConfigs";
import { CSVLink } from "react-csv";
import cancel from "../../../assets/images/cancel.webp";
import dotImg from "./expand.svg";
import tracker from "../../../assets/images/tracker.svg";

import "./lead.scss";
import {
  Box,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Switch,
  Typography,
} from "@mui/material";
import { AddFollowUp } from "../Followup/AddFollowUp";
import { UpdateFollowUp } from "../Followup/UpdateFollowup";
import { ScaleLoader } from "react-spinners";
import SearchReuse from "../../../component/Reusable/searchReuse";

const LeadList = () => {
  const darkThemeStyles = {
    input: (provided) => ({
      ...provided,
      color: "#fff",
    }),
    control: (provided) => ({
      ...provided,
      background: "#21212e",
      borderColor: "#131313",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#131313",
      },
    }),
    menu: (provided) => ({
      ...provided,
      background: "#21212e",
      color: "#fff",
    }),
    menuList: (provided) => ({
      ...provided,
      background: "#21212e",
      color: "#fff",
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? "#555" : "#333",
      color: "#fff",
      "&:hover": {
        background: "#444",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#fff",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#999",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#444",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#fff",
    }),
  };
  const fromMinDate = () => {
    let date = new Date();
    return date;
  };
  const initialPageData = { pageNo: 0, pageSize: 10 };
  const [response, setResponse] = useState({
    data: [],
    count: 0,
  });
  const [followUpUsers, setFollowUpUsers] = useState();
  const [AddFollowUpUsers, setAddFolloupUsers] = useState();
  const [Loading, setLoading] = useState(false);
  const [addLeadsModal, setAddLeadsModal] = useState(false);
  const [addFollowModal, setAddFollowModal] = useState(false);
  const [viewDetailsModal, setViewDetailsModal] = useState(false);
  const [viewFollowDetailsModal, setViewFollowDetailsModal] = useState(false);
  const [LoadingUp, setLoadingUp] = useState(false);
  const [viewDetails, setViewDetails] = useState();
  const [viewFlowDetails, setViewFolwDetails] = useState({});
  console.log("viewFlowDetails: ", viewFlowDetails);
  const [filters, setFilter] = useState({
    status: "all",
    end_date: "",
    search_by: "all",
    start_date: "",
    ...initialPageData,
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    reset,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
  });
  const {
    handleSubmit: handleSubmitFollowAdd,
    register: registerFollowAdd,
    formState: { errors: errorsFollowAdd },
    control: controlFollowAdd,
    reset: resetFollowAdd,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
  });
  //leads update useform
  const {
    handleSubmit: handleUpdate,
    control: updateControl,
    formState: { errors: updateErrors },
    reset: updateReset,
    register: updateRegister,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      username: "",
      emailId: "",
      mobile_no: "",
      address: "",
      location: "",
    },
  });
  const {
    handleSubmit: handleFollowUpdate,
    control: updateFollowControl,
    formState: { errors: updateFollowErrors },
    reset: updateFollowReset,
    register: updateFollowRegister,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      follow_up_users: "",
      location: "",
      meeting_description: "",
      fromtime: "",
      totime: "",
    },
  });
  const toggleAdd = () => {
    setAddLeadsModal(!addLeadsModal);
  };
  const toggleAddClose = () => {
    setAddLeadsModal(false);
    reset();
  };

  //Leads View Details Modal Start
  const toggleView = (id) => {
    setViewDetailsModal(!viewDetailsModal);
    handleView(id);
  };

  const handleView = (id) => {
    let param = {
      id: id,
    };
    Helper.PostData("flow-new", "leads/single_leads_view", param, false)
      .then((res) => {
        if (res.status) {
          setViewDetails(res.data);
          setLoading(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const toggleViewDetailsClose = () => {
    setViewDetailsModal(false);
    updateReset();
  };

  //Goal View Details Modal End
  const leadList = () => {
    setLoading(true);
    Helper.PostData("flow-new", "leads/lead_list", filters, false).then(
      (res) => {
        if (res.status) {
          setResponse({
            data: res.data.result,
            count: res.data.total_count,
          });
          setLoading(false);
        } else {
          setResponse({
            data: [],
            count: 0,
          });
          setLoading(false);
        }
      }
    );
  };

  // Follow Up List -----------------------------------
  useEffect(() => {
    leadList();
  }, [filters]);
  const [viewFollow, setViewFollow] = useState(false);
  const [followUpRow, setFollowUpRow] = useState({});
  const toggleViewFollowUp = (row) => {
    setViewFollow(!viewFollow);
    setFollowUpRow(row);
    followUpList(row.useruniqueid);
  };
  const toggleViewFollowUpClose = () => {
    setViewFollow(false);
  };
  const [steps, setSteps] = useState([]);
  const [BtnStats, setBtnStats] = useState(0);
  const followUpList = (id) => {
    Helper.PostData(
      "flow-new",
      "leads/follow_up_list",
      { ...filters, useruniqueid: id },
      false
    ).then((res) => {
      if (res.status) {
        setSteps(res?.data?.result);
        setBtnStats(res?.data?.add_follow_status);
      } else {
        setSteps([]);
        setBtnStats(0);
      }
    });
  };
  useEffect(() => {
    Helper.PostData(
      "flow-new",
      "leads/follow_user_list_dropdown",
      {},
      false
    ).then((res) => {
      if (res.status) {
        let dataObj = res.data;
        const options = dataObj?.map((item) => ({
          label: item.username,
          value: item.useruniqueid,
        }));
        setFollowUpUsers(dataObj);
        setAddFolloupUsers(options);
      }
    });
  }, []);
  const HandleAddFollowUp = (data) => {
    console.log("data: ", data);
    setLoadingAdd(true);
    let param = {
      ...data,
      // user_id: "",
      follow_up_users: followUpRow?.username,
      uniqueId: followUpRow?.useruniqueid,
      fromtime: moment(data.fromtime).format("YYYY-MM-DD HH:mm:ss"),
      totime: moment(data.totime).format("YYYY-MM-DD HH:mm:ss"),
    };
    console.log("param: ", param);
    Helper.PostData("flow-new", "leads/follow_up_user", param, true)
      .then((res) => {
        if (res.status) {
          followUpList(followUpRow?.useruniqueid);
          setLoadingAdd(false);
          setAddFollowModal(false);
          reset();
        }
      })
      .finally(() => {
        setLoadingAdd(false);
      });
  };
  const [modalInfo, setModalInfo] = useState(false);
  const [LeadInfo, setLeadInfo] = useState({});
  console.log("LeadInfo: ", LeadInfo);

  const toggleLeadInfo = (info) => {
    setModalInfo(!modalInfo);
    setLeadInfo(info);
  };

  const columns = [
    {
      name: "S.No",
      sortable: true,
      selector: (row, index) => row.index,
      cell: (row, index) => <td>{index + 1}</td>,
      width: "100px",
    },

    {
      name: "User Name",
      selector: (row, index) => row.username,
      sortable: true,
      cell: (row, index) => (
        <td className="text-center cursor-pointer">{row.username}</td>
      ),
    },
    {
      name: "Mobile",
      sortable: true,
      selector: (row, index) => row.mobile_no,
      cell: (row, index) => (
        <td className="text-center cursor-pointer">{row.mobile_no}</td>
      ),
    },
    {
      name: "Location",
      sortable: true,
      selector: (row, index) => row.location,
      cell: (row, index) => (
        <td className="text-center cursor-pointer">{row.location}</td>
      ),
    },
    {
      name: "Status",
      sortable: true,
      selector: (row, index) => row.status,
      cell: (row, index) => (
        <td className="text-center wwwdsafsdfdsfs">
          {row.status == 0 ? (
            <span className="text-warning">Pending</span>
          ) : row.status == 1 ? (
            <span className="text-success">Completed</span>
          ) : (
            <span className="text-danger">Rejected</span>
          )}
        </td>
      ),
    },
    {
      name: "Action",
      sortable: true,
      selector: (row, index) => row.id,
      cell: (row, index) => (
        <td className="text-center cursor-pointer">
          <FontAwesomeIcon
            icon={faEye}
            className="text-primary cursor-pointer ml-2"
            onClick={() => toggleLeadInfo(row)}
            title="View Lead"
          />
          {row.status == 0 && (
            <FontAwesomeIcon
              icon={faPen}
              onClick={() => toggleView(row.id)}
              className="text-light ml-2"
              title="Edit Lead"
            />
          )}
          {/* <FontAwesomeIcon
            icon={faUser}
            className="text-success cursor-pointer ml-2"
            onClick={() => toggleViewFollowUp(row)}
            title="FollowUp Details"
          /> */}
          {row.status == 0 && (
            <img
              src={tracker}
              className="img-fluid cursor-pointer ml-2"
              width={18}
              onClick={() => toggleViewFollowUp(row)}
            />
          )}
        </td>
      ),
    },
  ];

  const [LoadingAdd, setLoadingAdd] = useState(false);

  const HandleAddLeads = (data) => {
    setLoadingAdd(true);

    let param = {
      ...data,
    };
    Helper.PostData("flow-new", "leads/create_leads", param, true)
      .then((res) => {
        if (res.status) {
          setLoadingAdd(false);
          setAddLeadsModal(false);
          reset();
          leadList();
        }
      })
      .finally(() => {
        setLoadingAdd(false);
      });
  };

  //handle update Leads
  const HandleUpdateLeads = (data) => {
    setLoadingAdd(true);
    let params = {
      id: viewDetails.id,
      emailId: data?.emailId ? data?.emailId : viewDetails?.emailId,
      mobile_no: data?.mobile_no ? data?.mobile_no : viewDetails?.mobile_no,
      address: data?.address ? data?.address : viewDetails?.address,
      location: data?.location ? data?.location : viewDetails?.location,
      username: viewDetails?.username,
    };
    Helper.PostData("flow-new", "leads/edit_leads", params, true)
      .then((resp) => {
        if (resp.status) {
          setViewDetailsModal(false);
          updateReset();
          leadList();
          setLoadingAdd(false);
        }
      })
      .finally(() => {
        // setViewDetailsModal(false)
        setLoadingAdd(false);
      });
  };
  useEffect(() => {
    if (viewDetails) {
      updateReset({
        username: viewDetails.username || "",
        emailId: viewDetails.emailId || "",
        mobile_no: viewDetails.mobile_no || "",
        address: viewDetails.address || "",
        location: viewDetails.location || "",
      });
    }
  }, [viewDetails, updateReset]);

  let header = [
    { label: "First Name", key: "first_name" },
    { label: "Last Name", key: "last_name" },
    { label: "Email", key: "emailId" },
    { label: "Mobile No", key: "mobile_no" },
    { label: "Address", key: "address" },
    { label: "Location", key: "location" },
  ];
  const csvReport = {
    data: response?.data,
    headers: header,
    filename: "LeadsReport.csv",
  };
  const toggleAddFollow = () => {
    setAddFollowModal(!addFollowModal);
    resetFollowAdd();
  };
  const toggleAddFollowClose = () => {
    setAddFollowModal(false);
    reset();
  };
  const [StatusChange, setStatusChange] = useState(false);
  const [updateRemarkModal, setUpdateRemarkModal] = useState(false);
  const toggleUpdateRemark = (id) => {
    console.log("id: ", id);
    let param = {
      id: id,
    };
    Helper.PostData("flow-new", "leads/single_follow_up_view", param, false)
      .then((res) => {
        if (res.status) {
          setUpdateRemarkModal(!updateRemarkModal);
          setViewFolwDetails(res.data);
          setLoading(false);
          leadList();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const toggleUpdateRemarkClose = () => {
    setUpdateRemarkModal(false);
    setStatusChange(false);
    updateFollowReset();
  };
  const handleStatusChange = (check, stepid) => {
    setStatusChange(check);
    if (check) {
      toggleUpdateRemark(stepid);
    }
  };
  const OnUpdateStatusRemark = (data) => {
    setLoadingUp(true);
    let params = {
      remarks: data.remarks,
      status: data.status,
      meeting_description: viewFlowDetails?.meeting_description,
      location: viewFlowDetails?.location,
      id: viewFlowDetails.id,
      follow_up_users: viewFlowDetails?.follow_up_users,
      fromtime: moment(viewFlowDetails?.fromtime).format("YYYY-MM-DD HH:mm:ss"),
      totime: moment(viewFlowDetails?.totime).format("YYYY-MM-DD HH:mm:ss"),
      uniqueId: viewFlowDetails?.uniqueId,
    };
    Helper.PostData("flow-new", "leads/edit_follow_up", params, true)
      .then((resp) => {
        if (resp.status) {
          toggleUpdateRemarkClose();
          updateFollowReset({});
          followUpList(viewFlowDetails?.uniqueId);
          setLoadingUp(false);
        }
      })
      .finally(() => {
        // setViewDetailsModal(false);
        setLoadingUp(false);
      });
  };

  return (
    <Fragment>
      <div>
        <div className="trans-body">
          <div className="trans-TabCnt">
            <div className="stakingbonus-tabcnt">
              <div className="bonus-Head">
                <h5>Leads History</h5>
              </div>
              <div className="bonus-Body">
                <div className="tbl-header-items">
                  <div className="showentries-LeftCnt">
                    <div className="row inr-cnt">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <SearchReuse
                          setFilters={setFilter}
                          initialPageData={initialPageData}
                          placeholder={"Search"}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 text-center">
                        <button
                          className="btn btn-primary"
                          onClick={() => toggleAdd()}
                        >
                          Add Leads
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cmntbl">
                  <div className="table-responsive">
                    <div className="back-btn text-right cursor-pointer mb-4">
                      {/* <CSVLink {...csvReport}>
                        <FontAwesomeIcon
                          icon={faDownload}
                          className=""
                          size="sm"
                        />{" "}
                        Export
                      </CSVLink> */}
                    </div>
                    <DataTable
                      columns={columns}
                      className="text-center"
                      data={response.data}
                      paginationTotalRows={response.count}
                      customStyles={dataTableConfigs}
                      pagination={true}
                      paginationServer
                      onChangePage={(pageNo) => {
                        setFilter((prev) => ({ ...prev, pageNo: pageNo - 1 }));
                      }}
                      progressPending={Loading}
                      progressComponent={<TableLoader />}
                      noDataComponent={<NoDataComponent />}
                      onChangeRowsPerPage={(pageSize) => {
                        setFilter((prev) => ({ ...prev, pageSize }));
                      }}
                      persistTableHead={true}
                      paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={addLeadsModal}
        toggle={toggleAdd}
        className="modal-md modal-dialog-centered mymodal"
        size="md"
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader
          toggle={toggleAddClose}
          close={<CloseBtn onClick={() => toggleAddClose()} />}
        >
          Add Leads
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(HandleAddLeads)} autoComplete="off">
            <div class="row">
              <div className="col-12">
                <div class="form-group formInputs walletInput">
                  <label class="col-form-label">
                    User Name<span className="text-primary">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    name="username"
                    placeholder="Enter User Name"
                    {...register("username", {
                      required: "* User Name is required",
                      pattern: {
                        value: /[/^\S]/,
                        message: "* Whitespace not allowed ",
                      },
                    })}
                  />
                  {errors.username && (
                    <p className="text-danger">{errors.username.message}</p>
                  )}
                </div>
                <div class="form-group formInputs walletInput">
                  <label class="col-form-label">
                    Email<span className="text-primary"> (optional)</span>
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    name="emailId"
                    placeholder="Enter Email Id"
                    {...register("emailId", {
                      required: false,
                    })}
                  />
                </div>

                <div class="form-group formInputs walletInput">
                  <label class="col-form-label">
                    Mobile<span className="text-primary">*</span>
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    name="mobile_no"
                    placeholder="Enter Mobile Number"
                    {...register("mobile_no", {
                      required: "* Mobile Number is required",
                      pattern: {
                        value: /[/^\S]/,
                        message: "* Whitespace not allowed ",
                      },
                    })}
                  />
                  {errors.mobile_no && (
                    <p className="text-danger">{errors.mobile_no.message}</p>
                  )}
                </div>

                <div class="form-group formInputs walletInput">
                  <label class="col-form-label">
                    Address<span className="text-primary"> (optional)</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    name="address"
                    placeholder="Enter Address"
                    {...register("address", {
                      required: false,
                    })}
                  />
                </div>

                <div class="form-group formInputs walletInput">
                  <label class="col-form-label">
                    Location<span className="text-primary">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    name="location"
                    placeholder="Enter Location"
                    {...register("location", {
                      required: "* Location is required",
                      pattern: {
                        value: /[/^\S]/,
                        message: "* Whitespace not allowed ",
                      },
                    })}
                  />
                  {errors.location && (
                    <p className="text-danger">{errors.location.message}</p>
                  )}
                </div>
              </div>
            </div>
            <div class="text-center mb-2 mt-3">
              {LoadingAdd ? (
                <button
                  class="btn btn-primary px-4"
                  type="button"
                  disabled={LoadingAdd}
                >
                  <SpinnerLoader />
                </button>
              ) : (
                <button class="btn btn-primary px-4" type="submit">
                  Confirm
                </button>
              )}
            </div>
          </form>
        </ModalBody>
      </Modal>

      {/* leads View and Edit Modal */}
      <Modal
        isOpen={viewDetailsModal}
        toggle={toggleView}
        className="modal-md modal-dialog-centered mymodal"
        size="md"
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader
          toggle={toggleViewDetailsClose}
          close={<CloseBtn onClick={() => toggleViewDetailsClose()} />}
        >
          Update Lead Details
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleUpdate(HandleUpdateLeads)} autoComplete="off">
            <div class="row">
              <div className="col-12">
                <div class="form-group formInputs walletInput">
                  <label class="col-form-label">
                    User Name <span className="text-primary">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    name="username"
                    defaultValue={viewDetails?.username}
                    placeholder="Enter User Name"
                    disabled={viewDetails?.username}
                    {...updateRegister("username", {
                      required: false,
                    })}
                  />
                </div>

                <div class="form-group formInputs walletInput">
                  <label class="col-form-label">
                    Email <span className="text-primary"> (optional)</span>
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    name="emailId"
                    disabled={viewDetails?.emailId}
                    defaultValue={viewDetails?.emailId}
                    placeholder="Enter Email Id"
                    {...updateRegister("emailId", {
                      required: false,
                    })}
                  />
                </div>

                <div class="form-group formInputs walletInput">
                  <label class="col-form-label">
                    Mobile <span className="text-primary">*</span>
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    name="mobile_no"
                    defaultValue={
                      isNaN(viewDetails?.mobile_no)
                        ? ""
                        : +viewDetails?.mobile_no
                    }
                    placeholder="Enter Mobile Number"
                    {...updateRegister("mobile_no", {
                      required: "* Mobile Number is required",
                      pattern: {
                        value: /[/^\S]/,
                        message: "* Whitespace not allowed ",
                      },
                    })}
                  />
                  {updateErrors.mobile_no && (
                    <p className="text-danger">
                      {updateErrors.mobile_no.message}
                    </p>
                  )}
                </div>

                <div class="form-group formInputs walletInput">
                  <label class="col-form-label">
                    Address <span className="text-primary"> (optional)</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    name="address"
                    defaultValue={viewDetails?.address}
                    placeholder="Enter Address"
                    {...updateRegister("address", {
                      required: false,
                    })}
                  />
                </div>

                <div class="form-group formInputs walletInput">
                  <label class="col-form-label">
                    Location <span className="text-primary">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    name="location"
                    defaultValue={viewDetails?.location}
                    placeholder="Enter Location"
                    {...updateRegister("location", {
                      required: "* Location is required",
                      pattern: {
                        value: /[/^\S]/,
                        message: "* Whitespace not allowed ",
                      },
                    })}
                  />
                  {updateErrors.location && (
                    <p className="text-danger">
                      {updateErrors.location.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div class="text-center mb-2 mt-3">
              {LoadingAdd ? (
                <button
                  class="btn btn-primary px-4"
                  type="button"
                  disabled={LoadingAdd}
                >
                  <SpinnerLoader />
                </button>
              ) : (
                <button class="btn btn-primary px-4" type="submit">
                  Update Leads
                </button>
              )}
            </div>
          </Form>
        </ModalBody>
      </Modal>
      {/* Lead Info Modal */}
      <Modal
        isOpen={modalInfo}
        toggle={toggleLeadInfo}
        className="modal-md modal-dialog-centered mymodal"
        size="md"
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader
          toggle={toggleLeadInfo}
          close={<CloseBtn onClick={() => toggleLeadInfo()} />}
        >
          Lead Details
        </ModalHeader>
        <ModalBody>
          <>
            <div class="info">
              <span>User Name</span>
              <div className="text-success">{LeadInfo?.username}</div>
            </div>
            <div class="info">
              <span>Mobile No</span>
              <div>{LeadInfo?.mobile_no}</div>
            </div>
            <div class="info">
              <span>Email </span>
              <div>{LeadInfo?.emailId || "-"}</div>
            </div>
            <div class="info">
              <span>Address</span>
              <div>{LeadInfo?.address}</div>
            </div>
            <div class="info">
              <span>Location</span>
              <div>{LeadInfo?.location}</div>
            </div>
            <div class="info">
              <span>Status</span>
              <div className="wwwdsafsdfdsfs">
                {LeadInfo?.status == 0 ? (
                  <span className="text-warning">Pending</span>
                ) : LeadInfo?.status == 1 ? (
                  <span className="text-success">Completed</span>
                ) : (
                  <span className="text-danger">Rejected</span>
                )}
              </div>
            </div>
          </>
        </ModalBody>
      </Modal>
      {/*--------------------------Follow Details--------------------- */}

      <Modal
        isOpen={viewFollow}
        toggle={toggleViewFollowUp}
        className="modal-md modal-dialog-centered mymodal"
        size="md"
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader
          toggle={toggleViewFollowUpClose}
          close={
            <div className="d-flex align-items-baseline">
              <CloseBtn onClick={() => toggleViewFollowUpClose()} />
            </div>
          }
        >
          FollowUp Details
        </ModalHeader>
        <ModalBody className="modal-body-stepper">
          <>
            <div className="conatiner">
              <div className="row">
                <div className="col-6">
                  <p className="text-light">
                    Lead Name :{" "}
                    <span className="text-info fs-13">
                      {followUpRow?.username}
                    </span>
                  </p>
                </div>
                <div className="col-6">
                  <p className="text-light text-right">
                    Mobile No :{" "}
                    <span className="text-info fs-13">
                      {followUpRow?.mobile_no}
                    </span>
                  </p>
                </div>
              </div>
              <div className="tracking-leads">
                <div className="px-2">
                  <div className="lead-infos">
                    {steps?.map((item, index) => (
                      <>
                        <div className="row order-track-text">
                          <div
                            className={
                              item.remark == "" ? `col-lg-12` : `col-lg-6`
                            }
                          >
                            <div className="" key={index}>
                              <div className="row">
                                <div className="col-lg-12">
                                  <div
                                    className="d-flex align-items-baseline"
                                    style={{ gap: "10px" }}
                                  >
                                    <div className="dot-img">
                                      <img
                                        alt="cryptocurrency exchange"
                                        src={dotImg}
                                        className="mr-2"
                                      />
                                      <div className="vl"></div>
                                    </div>
                                    <div>
                                      <p className="order-track-text-stat" style={{wordBreak:"break-word"}}>
                                        {item.title}
                                      </p>
                                      <span className="fs-12 order-track-text-sub" style={{wordBreak:"break-word"}}>
                                        {item.description ||
                                          item.meeting_description}
                                      </span>
                                      <br />
                                      <span className="fs-12 order-track-text-sub" style={{wordBreak:"break-word"}}>
                                        Location :{" "}
                                        <span className="fs-12 text-info">
                                          {item.location}
                                        </span>
                                      </span>
                                      <br />
                                      <span className="fs-12 order-track-text-sub" style={{wordBreak:"break-word"}}>
                                        At :{" "}
                                        {item.created_at ? (
                                          <span className="fs-12 text-info">
                                            {item.created_at}
                                          </span>
                                        ) : (
                                          <span className="fs-12 text-info">
                                            {item.fromtime} - {item.totime}
                                          </span>
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {item.remark !== "" && (
                            <div className="col-lg-6">
                              <div className="vonom-pad" key={index}>
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div
                                      className="d-flex align-items-baseline"
                                      style={{ gap: "10px" }}
                                    >
                                      <div className="mt-4">
                                        {item.remark && (
                                          <span className="fs-12 order-track-text-sub">
                                            Remarks :{" "}
                                            <span className="fs-12 text-info">
                                              {item.remark}
                                            </span>
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="pos-lead-sts">
                            {(() => {
                              if (item.status == 4) {
                                return (
                                  <p className="text-primaryl fs-12 fw-500">
                                    Registered
                                  </p>
                                );
                              } else if (item.status == 3) {
                                return (
                                  <p className="text-dangerl fs-12 fw-500">
                                    Rejected
                                  </p>
                                );
                              } else if (item.status == 2) {
                                return (
                                  <p className="text-infol fs-12 fw-500">
                                    In Discussion
                                  </p>
                                );
                              } else if (item.status == 1) {
                                return (
                                  <p className="text-successl fs-12 fw-500">
                                    Completed
                                  </p>
                                );
                              } else {
                                return (
                                  <p className="text-warningl fs-12 fw-500">
                                    In Schedule
                                  </p>
                                );
                              }
                            })()}
                          </div>
                          {item.status == 0 && (
                            <div
                              className="mt-3 cursor-pointer"
                              style={{ margin: "auto" }}
                            >
                              <button
                                className="btn btn-primary"
                                onClick={() =>
                                  handleStatusChange(true, item.id)
                                }
                              >
                                Update FollowUp
                              </button>
                            </div>
                          )}
                        </div>
                      </>
                    ))}
                  </div>
                  {BtnStats == 1 && (
                    <div className="text-center mt-3">
                      <button
                        className="btn btn-primary"
                        onClick={() => toggleAddFollow()}
                      >
                        <FontAwesomeIcon icon={faPlus} className="mr-2" />{" "}
                        Schedule FollowUp
                      </button>
                    </div>
                  )}
                </div>{" "}
              </div>
            </div>
          </>
        </ModalBody>
      </Modal>
      {/* FollowUP Add */}
      <AddFollowUp
        addLeadsModal={addFollowModal}
        fromMinDate={fromMinDate}
        followUpRow={followUpRow}
        toggleAdd={toggleAddFollow}
        toggleAddClose={toggleAddFollowClose}
        handleSubmit={handleSubmitFollowAdd}
        register={registerFollowAdd}
        control={controlFollowAdd}
        LoadingAdd={LoadingAdd}
        HandleAddFollowUp={HandleAddFollowUp}
        errors={errorsFollowAdd}
        darkThemeStyles={darkThemeStyles}
        AddFollowUpUsers={AddFollowUpUsers}
      />
      {/* FollowUP Status Update */}
      <Modal
        isOpen={updateRemarkModal}
        toggleModal={toggleUpdateRemark}
        centered
        size="sm"
        className="modal-md modal-dialog-centered mymodal"
      >
        <ModalHeader
          toggle={toggleUpdateRemarkClose}
          close={<CloseBtn onClick={() => toggleUpdateRemarkClose()} />}
        >
          Are you sure want to update your status ?
        </ModalHeader>
        <ModalBody>
          <form
            onSubmit={handleFollowUpdate(OnUpdateStatusRemark)}
            autoComplete="off"
          >
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group formInputs loginInput mb-4">
                  <label>
                    Status<span className="text-primary">*</span>
                  </label>
                  <select
                    className="form-control"
                    name="status"
                    {...updateFollowRegister("status", {
                      required: "* Status is required",
                    })}
                  >
                    <option value="">Select Status</option>
                    <option value={2}>In Discussion</option>
                    <option value={1}>Completed</option>
                    <option value={3}>Rejected</option>
                  </select>
                  {updateFollowErrors.status && (
                    <p className="text-danger">
                      {updateFollowErrors.status.message}
                    </p>
                  )}
                </div>
                <div className="form-group formInputs loginInput mb-4">
                  <label>
                    Remarks<span className="text-primary">*</span>
                  </label>
                  <textarea
                    class="form-control"
                    rows="5"
                    name="remarks"
                    placeholder="Enter Remarks"
                    formControlName="message"
                    {...updateFollowRegister("remarks", {
                      required: "* Remarks is required",
                      pattern: {
                        value: /[/^\S]/,
                        message: "* Whitespace not allowed ",
                      },
                    })}
                    autocomplete="off"
                  ></textarea>
                  {updateFollowErrors.remarks && (
                    <p className="text-danger">
                      {updateFollowErrors.remarks.message}
                    </p>
                  )}
                </div>
                <div class="text-center mb-2 mt-3">
                  {LoadingUp ? (
                    <button
                      class="btn btn-primary px-4"
                      type="button"
                      disabled={LoadingUp}
                    >
                      <SpinnerLoader />
                    </button>
                  ) : (
                    <button class="btn btn-primary px-4" type="submit">
                      Confirm
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default LeadList;
