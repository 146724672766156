import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import Helper from '../../Helper/Helper'
const CopyDescriptionTable = ({ data, formatValue = true, index }) => {
	const [copiedIndex, setCopiedIndex] = useState(-1)

	const copyStatus = (index, setCopyIndex) => {
		setCopyIndex(index)
		setTimeout(() => {
			setCopyIndex(-1)
		}, 500)
	}

	return (
		<>
			{(() => {
				if (data) {
					if (formatValue) {
						return Helper.formatDescription(data)
					} else {
						return data
					}
				} else {
					return '---'
				}
			})()}
			{(() => {
				if (data) {
					if (copiedIndex == index) {
						return <span className='ml-2'>✔</span>
					} else {
						return (
							<FontAwesomeIcon
								className='cursor-pointer ml-2'
								style={{ color: 'green' }}
								icon={faCopy}
								title='Copy'
								onClick={() =>
									Helper.copyText(data, copyStatus, index, setCopiedIndex)
								}
							/>
						)
					}
				}
			})()}
		</>
	)
}

export default CopyDescriptionTable
