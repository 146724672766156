import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import CloseBtn from "../../../component/Reusable/CloseBtn";
import { setInvestorModal } from "../../../component/header/headerslice";
import "../staking/staking.scss";
import Helper from "../../../Helper/Helper";

const InvestorModal = () => {
  const {
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    reset,
    register,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const toggle = () => setModal(!modal);
  const [type, setType] = useState("");
  const Profile = useSelector((state) => state.header.profileData);
  const invetorModal = useSelector((state) => state.header.investors);

  useEffect(() => {
    reset({});
    setModal(invetorModal);
  }, [invetorModal]);

  const handleFormSubmit = (formdata) => {
    setLoader(true);
    Helper.PostData("inves", "crm/addInvestor", {
      user_id: localStorage.getItem("tip"),
      username: Profile.username,
      name: formdata.name,
      mail_id: formdata.mail_id,
      mob_no: formdata.mob_no,
      investor_type: formdata.investor_type,
    })
      .then((res) => {
        if (res.status) {
          dispatch(setInvestorModal(false));
          setLoader(false);
          window.location.href="/user/dashboard?activeTab=14";
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className={"modal-md modal-dialog-centered mymodal"}
        size={"md"}
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader
          toggle={toggle}
          close={<CloseBtn onClick={() => dispatch(setInvestorModal(false))} />}
        >
          Add Investor
        </ModalHeader>
        <ModalBody className="px-4">
          <Form onSubmit={handleSubmit(handleFormSubmit)} autoComplete="off">
            <div class="row">
              <div class="col-12">
                <div className="row">
                  <div className="col-6">
                    <div class="form-group formInputs walletInput">
                      <label class="col-form-label">User Name</label>
                      <input
                        class="form-control"
                        name="username"
                        {...register("username")}
                        disabled
                        defaultValue={Profile.username}
                      />
                    </div>
                  </div>
                  <div class="form-group formInputs walletInput">
                    <label class="col-form-label">User ID</label>
                    <input
                      class="form-control"
                      name="user_id"
                      {...register("user_id")}
                      disabled
                      defaultValue={Profile.userrandomid}
                    />
                  </div>
                  <div class="col-12">
                    <div class="form-group formInputs walletInput">
                      <label class="col-form-label">Name</label>
                      <input
                        class="form-control"
                        name="name"
                        placeholder="Enter Name"
                        {...register("name", {
                          required: "* Name is Required",
                        })}
                      />
                      {errors.name && (
                        <p className="text-danger">{errors.name.message}</p>
                      )}
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group formInputs walletInput">
                      <label class="col-form-label">
                        Email <span className="text-gray">(Optional)</span>
                      </label>
                      <input
                        class="form-control"
                        name="mail_id"
                        placeholder="Enter Email"
                        {...register("mail_id", {
                          required: false,
                        })}
                      />
                      {errors.mail_id && (
                        <p className="text-danger">{errors.mail_id.message}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div class="form-group formInputs walletInput">
                      <label class="col-form-label">Investor Type</label>
                      <select
                        class="form-control"
                        name="investor_type"
                        {...register("investor_type", {
                          required: "* Investor type is required",
                        })}
                      >
                        <option hidden value="">
                          Select Investor Type
                        </option>
                        <option value="leader">Leader</option>
                        <option value="investor">Investor</option>
                      </select>
                      {errors.investor_type && (
                        <p className="text-danger">
                          {errors.investor_type.message}
                        </p>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-6">
                    <div class="form-group formInputs walletInput">
                      <label class="col-form-label">Type</label>
                      <select
                        class="form-control"
                        name="type"
                        {...register("type", {
                          required: "* Type is required",
                        })}
                        onChange={({ target: { value } }) => {
                          setType(value);
                        }}
                      >
                        <option hidden value="">
                          Select Type
                        </option>
                        <option value="add">Add</option>
                        <option value="convert">Convert</option>
                      </select>
                      {errors.type && (
                        <p className="text-danger">{errors.type.message}</p>
                      )}
                    </div>
                  </div> */}

                  <div className="col-6">
                    <div class="form-group formInputs walletInput">
                      <label class="col-form-label">Mobile Number</label>
                      <input
                        class="form-control"
                        name="mob_no"
                        type="number"
                        placeholder="Enter Mobile Number"
                        maxLength={18}
                        {...register("mob_no", {
                          required: "* Mobile number is required",
                          pattern: {
                            value: /^\d*$/,
                            message: "* Must number should allowed ",
                          },
                          maxLength: {
                            value: 18,
                            message: "* Maximum within 18 Digits",
                          },
                        })}
                      />
                      {errors.mob_no && (
                        <p className="text-danger">{errors.mob_no.message}</p>
                      )}
                    </div>
                  </div>
                  {type == "convert" && (
                    <div class="col-6">
                      <div class="form-group formInputs walletInput">
                        <label class="col-form-label">Amount</label>
                        <input
                          class="form-control"
                          name="amount"
                          type="number"
                          {...register("amount", {
                            required: "* Amount is Required",
                          })}
                        />
                        {errors.amount && (
                          <p className="text-danger">{errors.amount.message}</p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div class="text-center mb-2">
                  {loader ? (
                    <button
                      class="btn btn-primary px-4"
                      type="button"
                      disabled={loader}
                    >
                      <Spinner size="sm" />
                    </button>
                  ) : (
                    <button class="btn btn-primary px-4" type="submit">
                      Confirm
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default InvestorModal;
