import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  network_type_dis: "",
};

const GetNetworkTypeDis = createSlice({
  name: "NetworkDis",
  initialState,
  reducers: {
    setDepositNetowork: (state, action) => {
      state.network_type_dis = action.payload;
    },
  },
});

export const { setDepositNetowork } = GetNetworkTypeDis.actions;
export default GetNetworkTypeDis.reducer;
