import React, { useEffect, useState } from "react";
import Helper from "../../Helper/Helper";
import "./batch.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import {
  OverallBatchReport,
  IndividualBatchReport,
} from "./OverallBatchReport";
import CopyData2 from "../../component/Reusable/CopyData 2";
import moment from "moment";

const BatchReports = () => {
  const initialPageData = { pageNo: 0, pageSize: 10 };
  const [Loading, setLoading] = useState(false);
  const [BatchList, setBatchList] = useState([]);
  const [FilterMonth, setFilterMonth] = useState([]);
  const [BatchMember, setBatchMember] = useState({ data: [], count: 0 });
  const [ShowManual, setShowManual] = useState(0);
  const [UserId, setUserId] = useState("");
  const [filters, setFilter] = useState({
    batchRank: "",
    month: "",
    userName: "",
  });
  const HandleViewRecords = (user_id) => {
    setShowManual(1);
    setUserId(user_id);
  };
  const columns = [
    {
      name: "S.No",
      sortable: true,
      selector: (row, index) => row.index,
      cell: (row, index) => <td>{index + 1}</td>,
    },
    {
      name: "User Id",
      sortable: true,
      selector: (row, index) => row.user_id,
    },
    {
      name: "User Name",
      selector: (row) => row.user_name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      cell: (row) => (
        <td>
          <CopyData2 data={row.email} />
        </td>
      ),
      sortable: true,
    },
    {
      name: "Batch Rank",
      selector: (row) => row.batch_rank,
      sortable: true,
    },
    {
      name: "Batch Updated Date",
      selector: (row) => row.batch_update_date,
      sortable: true,
      cell: (row, index) => {
        return (
          <td key={index + 1}>
            {row.batch_update_date !== ""
              ? moment(row.batch_update_date).format("YYYY-MM-DD")
              : "--"}
          </td>
        );
      },
    },
    {
      name: filters?.month ? "Selected Team Volume" : "Total Team Volume",
      selector: (row) =>
        filters?.month ? row.selected_team_volume : row.total_team_volume || 0,
      sortable: true,
    },
    {
      name: filters?.month ? "Selected Members Count" : "Total Members Count",
      selector: (row) =>
        filters?.month
          ? row.selected_members_count
          : row.total_member_count || 0,
      sortable: true,
    },

    {
      name: "Total stake Volume",
      selector: (row) => row.total_stake_volume || 0,
      sortable: true,
    },
    {
      name: "Total Growth Level",
      selector: (row) => row.total_growth_level_bonus || 0,
      sortable: true,
    },
    {
      name: "Total Level Bonus",
      selector: (row) => row.total_level_bonus || 0,
      sortable: true,
    },
    {
      name: "Total Investor Count",
      selector: (row) => row.isHaveInvestor_count || 0,
      sortable: true,
    },
    {
      name: "Investor Details",
      selector: (row) => {
        return (
          <FontAwesomeIcon
            icon={faEye}
            className="cursor-pointer"
            onClick={() => HandleViewRecords(row.user_id)}
          />
        );
      },
      sortable: true,
    },
  ];

  useEffect(() => {
    HandleChangeBatch();
  }, [filters]);

  useEffect(() => {
    Helper.GetData("", "stake/batchList", false).then((res) => {
      if (res.status) {
        setBatchList(res.data);
        setFilterMonth(res.fliter_month);
      }
    });
  }, []);

  const HandleChangeBatch = () => {
    setLoading(true);
    Helper.PostData("", "stake/batch_member_list_new", filters, false)
      .then((res) => {
        if (res.status) {
          setBatchMember({ data: res.data, count: res.count });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  let header1 = [
    { label: "User Id", key: "user_id" },
    { label: "User Name", key: "user_name" },
    { label: "Email", key: "email" },
    { label: "Batch Rank", key: "batch_rank" },
    { label: "Batch Updated Date", key: "batch_update_date" },
    {
      label: filters?.month ? "Selected Team Volume" : "Total Team Volume",
      key: filters?.month ? "selected_team_volume" : "total_team_volume",
    },
    {
      label: filters?.month ? "Selected Member Count" : "Total Member Count",
      key: filters?.month ? "selected_members_count" : "total_member_count",
    },
    { label: "Total Investor Count", key: "isHaveInvestor_count" },
    { label: "Total stake Volume", key: "total_stake_volume" },
    { label: "Total Growth Level", key: "total_growth_level_bonus" },
    { label: "Total Level Bonus", key: "total_level_bonus" },
  ];
  return (
    <div>
      {ShowManual == 0 ? (
        <OverallBatchReport
          Loading={Loading}
          BatchList={BatchList}
          BatchMember={BatchMember}
          setFilter={setFilter}
          columns={columns}
          FilterMonth={FilterMonth}
          header1={header1}
        />
      ) : (
        <IndividualBatchReport
          Loading={Loading}
          BatchList={BatchList}
          BatchMember={BatchMember}
          setFilter={setFilter}
          columns={columns}
          UserId={UserId}
          ShowManual={ShowManual}
          setShowManual={setShowManual}
        />
      )}
    </div>
  );
};

export default BatchReports;
