import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./contact.scss";
import uploadIcon from "../../assets/images/uploadIcon.svg";
import Header from "../../component/header";
import Footer from "../../component/footer";
import ScrollToTop from "../../component/utils/useScrollToTop";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import Helper from "../../Helper/Helper";
import metadata from "../../metaData/metadata";
import { Helmet } from "react-helmet";
import TicketHistory from "./ticketHistory";
import { SpinnerLoader } from "../../component/Loaders";
import Preview from "../../component/Reusable/Preview";
import NewHeader from "../../component/header/NewHeader";
const Contactuspage = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
  });
  const [loading, setLoading] = useState(false);
  const [ImageLoading, setImageLoading] = useState({ img: "", loading: false });
  const [ImageUrls, setImageUrls] = useState({ image: "" });
  const [historyApiCall, setHistoryApiCall] = useState(0);

  const HandleContact = (data) => {
    let params = {
      ...data,
      ...ImageUrls,
      user_id: localStorage.getItem("tip"),
    };
    console.log("params: ", params);
    setLoading(true);
    Helper.PostData("arun", "user/userAddcomments", params, true)
      .then((res) => {
        if (res.status) {
          // console.log('res-----------------', res)
          reset();
          setImageUrls({ image: "" });
          setHistoryApiCall();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const isCheckFileType = (val) => {
    let imgtype = val[0].type;
    // let imgsize = Math.round(val[0].size / 1024)
    if (
      imgtype != "image/jpg" &&
      imgtype != "image/jpeg" &&
      imgtype != "image/png"
    ) {
      return "* Only JPG, JPEG, PNG files are allowed";
    }
    // else if (imgsize > 1024) {
    // 	return 'File is too large.Please upload below 1MB'
    // }
    else {
      return true;
    }
  };

  const getImageURL = (val, img) => {
    let imgtype = val[0].type;
    if (
      imgtype != "image/jpg" &&
      imgtype != "image/jpeg" &&
      imgtype != "image/png"
    ) {
      console.log({ imgtype });
    } else {
      setImageLoading({ img, loading: true });
      const formData = new FormData();
      formData.append("image", val[0]);
      Helper.PostImageData("user", "user/profileUpload", formData)
        .then((res) => {
          if (res.status) {
            setImageUrls((prev) => ({
              ...prev,
              [img]: res.data.imageurl,
            }));
          }
        })
        .finally(() => setImageLoading({ img: "", loading: false }));
    }
  };

  return (
    <>
      <Helmet>
        <title>{metadata.contact.title}</title>
        <meta name="description" content={metadata.contact.description} />
        <meta name="keywords" content={metadata.contact.keywords} />
      </Helmet>
      <ScrollToTop />
      <div class="maincontent leftrightCir pt-2">
        <section class="pb-5">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="supportcardbox">
                  <div class="row justify-content-center">
                    <div class="col-lg-6">
                      <div class="fs-24 fw-600 mb-3 text-white mt-4 mt-lg-0">
                        Support Form
                      </div>
                      <div class="lightbgCard p-3 p-lg-4">
                        <Form
                          onSubmit={handleSubmit(HandleContact)}
                          autoComplete="off"
                        >
                          <div class="form-group formInputs walletInput mb-3">
                            <label class="">
                              Subject
                              <span className="text-primary">*</span>
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              name="subject"
                              placeholder="Enter Subject"
                              autocomplete="off"
                              {...register("subject", {
                                required: "* Subject is required",
                                pattern: {
                                  value: /[A-Za-z0-9]/,
                                  message: "* Invalid Subject ",
                                },
                              })}
                              maxLength={25}
                            />
                            {errors.subject && (
                              <p className="text-danger">
                                {errors.subject.message}
                              </p>
                            )}
                          </div>

                          <div class="form-group formInputs walletInput mb-3">
                            <label class="">
                              Description
                              <span className="text-primary">*</span>
                            </label>
                            <textarea
                              class="form-control"
                              rows="5"
                              name="description"
                              placeholder="Enter Description"
                              formControlName="message"
                              {...register("description", {
                                required: "*Description is required",
                                pattern: {
                                  value: /[A-Za-z0-9]/,
                                  message: "* Invalid Description ",
                                },
                              })}
                              maxLength={246}
                              autocomplete="off"
                            ></textarea>
                            {errors.description && (
                              <p className="text-danger">
                                {errors.description.message}
                              </p>
                            )}
                          </div>
                          <div class="form-group formInputs walletInput mb-3">
                            <label class="">Image</label>
                            <input
                              class="filetype"
                              type="file"
                              id="fileInput"
                              name="image"
                              disabled={ImageLoading.loading}
                              {...register("image", {
                                onChange: ({ target: { files } }) =>
                                  getImageURL(files, "image"),
                                validate: (val) => {
                                  if (val.length != 0) {
                                    return isCheckFileType(val);
                                  } else {
                                    return true;
                                  }
                                },
                              })}
                              autocomplete="off"
                            />
                            <label
                              for=""
                              class="lightbluedashed text-center"
                            >
                              {(() => {
                                if (ImageLoading.loading) {
                                  return <SpinnerLoader color="blue" />;
                                } else if (ImageUrls.image) {
                                  return (
                                    <>
                                      <Preview src={ImageUrls.image}>
                                        <img
                                          src={ImageUrls.image}
                                          alt="kycimg2"
                                          class="img-fluid mb-3 kycimg2"
                                        />
                                      </Preview>
                                      <div class="text-center fs-14 fw-400 text-white mb-3"></div>
                                    </>
                                  );
                                } else {
                                  return (
                                    <>
                                      <img
                                        src={uploadIcon}
                                        alt="kycimg2"
                                        class="img-fluid mb-3 kycimg2"
                                      />
                                      <div class="text-center fs-14 fw-400 text-white mb-3">
                                        Upload Image (Optional)
                                      </div>
                                    </>
                                  );
                                }
                              })()}
                              {!ImageLoading.loading && (
                                <label
                                  for="fileInput"
                                  class="btn btn-secondary "
                                >
                                  Choose File
                                </label>
                              )}
                            </label>
                            {errors.image && (
                              <p className="text-danger">
                                {errors.image.message}
                              </p>
                            )}
                          </div>
                          <div class="text-center">
                            {(() => {
                              if (loading) {
                                return (
                                  <button
                                    class="btn btn-primary"
                                    disabled
                                    type="button"
                                  >
                                    <SpinnerLoader />
                                  </button>
                                );
                              } else if (ImageLoading.loading) {
                                return (
                                  <button
                                    class="btn btn-primary"
                                    disabled
                                    type="button"
                                  >
                                    Submit
                                  </button>
                                );
                              } else {
                                return (
                                  <button class="btn btn-primary" type="submit">
                                    Submit
                                  </button>
                                );
                              }
                            })()}
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <TicketHistory apiCall={historyApiCall} />
          </div>
        </section>
      </div>
    </>
  );
};

export default Contactuspage;
