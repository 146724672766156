import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import serarchi from "../../assets/images/search-icon.svg";
import { TableLoader } from "../../component/Loaders";
import DatePickerReuse from "../../component/Reusable/DatePickerReuse";
import NoDataComponent from "../../component/Reusable/no-data-component";
import { dataTableConfigs } from "../../configs/dataTableConfigs";
import Helper from "../../Helper/Helper";

const DepositHistory = () => {
  const [response, setResponse] = useState([]);
  const [searchValue, setSearchData] = useState();
  const [copied, setCopied] = useState({ status: false, index: -1 });
  const [copied1, setCopied1] = useState({ status: false, index: -1 });
  const [copied2, setCopied2] = useState({ status: false, index: -1 });
  const [loading, setLoading] = useState(false);

  const [Filter, setFilter] = useState({
    user_id: localStorage.getItem("tip"),
    currency_symbol: "all",
    search_by: "all",
    status: "all",
    start_date: "",
    end_date: "",
    pageNo: 0,
    pageSize: 10,
  });

  useEffect(() => {
    setLoading(true);
    Helper.PostData(
      "wallet",
      "address/crypto_deposit_history",
      Filter,
      false
    ).then((res) => {
      if (res.status) {
        setResponse(res.data.total_results);
        setLoading(false);
      } else {
        setResponse([]);
        setLoading(false);
      }
    });
  }, [Filter]);

  const copyStatus = (index, setCopy) => {
    setCopy({ status: true, index });
    setTimeout(() => {
      setCopy({ status: false, index: -1 });
    }, 500);
  };

  const columns = [
    {
      name: "S.No",
      sortable: true,
      selector: (row, index) => row.index,
      cell: (row, index) => <td>{index + 1}</td>,
      maxWidth: "100px",
    },
    {
      name: "Amount",
      sortable: true,
      selector: (row, index) => row.amount,
      cell: (row, index) => <td>{row.amount}</td>,
    },
    {
      name: "Currency",
      selector: "currency_name",
      sortable: true,
    },
    {
      name: "Address",
      sortable: true,
      selector: (row, index) => row.address,
      cell: (row, index) => {
        return (
          <td>
            {row.address ? (
              <>
                {Helper.formatAddress(row.address)}

                {"   "}
                {copied.status && copied.index === index ? (
                  <span className="ml-2">✔</span>
                ) : (
                  <FontAwesomeIcon
                    className="cursor-pointer "
                    style={{ color: "green" }}
                    icon={faCopy}
                    title="Copy"
                    onClick={() =>
                      Helper.copyText(row.address, copyStatus, index, setCopied)
                    }
                  />
                )}
              </>
            ) : (
              " -- "
            )}
          </td>
        );
      },
    },
    {
      name: "Network",
      selector: "network",
      sortable: true,
      cell: (row, index) => {
        return <td>{row.network?.toUpperCase()}</td>;
      },
    },
    {
      name: "Tnx.Id",
      sortable: true,
      selector: (row, index) => row.transaction_id,
      cell: (row, index) => {
        return (
          <td>
            {row.transaction_id ? (
              <>
                {Helper.formatAddress(row.transaction_id)}
                {"   "}
                {copied1.status && copied1.index === index ? (
                  <span className="ml-2">✔</span>
                ) : (
                  <FontAwesomeIcon
                    className="cursor-pointer"
                    style={{ color: "green" }}
                    icon={faCopy}
                    title="Copy"
                    onClick={() =>
                      Helper.copyText(
                        row.transaction_id,
                        copyStatus,
                        index,
                        setCopied1
                      )
                    }
                  />
                )}
              </>
            ) : (
              " -- "
            )}
          </td>
        );
      },
    },
    {
      name: "Tnx.Hash",
      sortable: true,
      selector: (row, index) => row.hash,
      cell: (row, index) => {
        return (
          <td>
            {row.hash ? (
              <>
                {Helper.formatAddress(row.hash)}
                {"   "}
                {copied2.status && copied2.index === index ? (
                  <span className="ml-2">✔</span>
                ) : (
                  <FontAwesomeIcon
                    className="cursor-pointer"
                    style={{ color: "green" }}
                    icon={faCopy}
                    title="Copy"
                    onClick={() =>
                      Helper.copyText(row.hash, copyStatus, index, setCopied2)
                    }
                  />
                )}
              </>
            ) : (
              " -- "
            )}
          </td>
        );
      },
    },
    {
      name: "Explorer",
      sortable: true,
      selector: (row, index) => row.explorer,
      cell: (row, index) => {
        return (
          <td>
            {row.explorer ? (
              <a href={row.explorer} target="__blank">
                {row.status != 1 ? "--" : Helper.formatAddress(row.explorer)}
                {"   "}
              </a>
            ) : (
              " -- "
            )}
          </td>
        );
      },
    },
    {
      name: "Status",
      sortable: true,
      selector: (row, index) => row.status,
      cell: (row, index) => {
        return (
          <td>
            {row.status === 0 || row.status === "0" ? (
              <p className="" style={{ color: "#f9dc49" }}>
                Pending
              </p>
            ) : row.status === 1 || row.status === "1" ? (
              <p className="text-success">Completed</p>
            ) : row.status === 2 || row.status === "2" ? (
              <p className="text-danger">Rejected</p>
            ) : (
              ""
            )}
          </td>
        );
      },
    },
    {
      name: "Date & Time",
      sortable: true,
      selector: (row, index) => row.created_at,
      cell: (row, index) => (
        <td className="text-center">
          {moment(row.created_at).format("YYYY-MM-DD, h:mm:ss A")}
        </td>
      ),
    },
  ];
  const exportFile = useCallback(() => {
    const headers = {
      sno: "S.No",
      amount: "Amount",
      address: "Address",
      network: "Network",
      txn_id: "Transaction Id",
      txn_hsh: "Transaction Hash",
      status: "Status",
      created_at: "Date/Time",
    };
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Data");
    // Add header row
    worksheet.addRow([
      headers.sno,
      headers.amount,
      headers.address,
      headers.network,
      headers.txn_id,
      headers.txn_hsh,
      headers.status,
      headers.created_at,
    ]);

    // Style the header row
    worksheet.getRow(1).font = { bold: true };

    // Add data rows
    response?.forEach((item, index) => {
      worksheet.addRow([
        index + 1 || "-",
        item.amount || "-",
        item.address || "-",
        item.network || "-",
        item.transaction_id || "-",
        item.hash || "-",
        item.status === 0
          ? "Pending"
          : item.status === 1
          ? "Completed"
          : "Rejected",
        moment(item.created_at).format("YYYY-MM-DD, h:mm:ss A") || "-",
      ]);
    });

    // Write to file as a blob
    workbook.xlsx
      .writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "DepositHistory.xlsx");
      })
      .catch((error) => {
        console.error("Error generating file:", error);
      });
  }, [response]);

  const customLoader = <TableLoader />;

  return (
    <div>
      <div class="row mt-4">
        <div class="col-lg-12">
          <div class="bonus-Body">
            <div className="d-lg-flex justify-content-between flex-wrap">
              <div className="mt-3 mr-2">
                <h5>Deposit History</h5>
              </div>
              <div className="">
                <div class="tbl-showentriessearch flex-wrap d_Fds     justify-content-start">
                  <div class="showentries-LeftCnt">
                    <div class="inr-showecnt">
                      <button
                        class="btn text-primary cursor-pointer"
                        type="button"
                        onClick={() => exportFile()}
                      >
                        <i class="fas fa-file-export"></i> Export
                      </button>
                    </div>
                  </div>
                  <DatePickerReuse setFilters={setFilter} />
                  <div class="showentries-RightCnt showentries-RightCnt">
                    <div class="inr-searchcnt d_Fds2">
                      <div className="mx-md-2 my-2">
                        <select
                          name=""
                          id=""
                          className="form-control"
                          onChange={({ target: { value } }) => {
                            setFilter((prev) => ({
                              ...prev,
                              currency_symbol: value,
                            }));
                          }}
                        >
                          <option value="all">All</option>
                          <option value="KPK">KPK</option>
                          <option value="KUSD">KUSD</option>
                          <option value="USDT">USDT</option>
                        </select>
                      </div>
                      <div class="search-input">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Address/Tnx.Id/Tnx.hash"
                          autocomplete="off"
                          onChange={({ target: { value } }) => {
                            if (!value) {
                              setFilter((prev) => ({
                                ...prev,
                                search_by: "all",
                              }));
                            }
                            setSearchData(() => value);
                          }}
                        />
                        <span>
                          {" "}
                          <img
                            src={serarchi}
                            style={{ cursor: "pointer" }}
                            class="img-fluid cursor-pointer"
                            alt=""
                            onClick={(e) => {
                              e.preventDefault();
                              if (searchValue) {
                                setFilter((prev) => ({
                                  ...prev,
                                  search_by: searchValue,
                                }));
                              }
                            }}
                          />{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-md-6 ">
                <div class="sDashtit mt-3">
                  <h5>Deposit History</h5>
                </div>
              </div>
              <div className="col-md-6">
                <div class="tbl-showentriessearch">
                  <div class="showentries-LeftCnt">
                    <div class="inr-showecnt">
                      <button class="btn text-primary" type="button">
                        <CSVLink {...csvReport}>
                          {" "}
                          <i class="fas fa-file-export"></i> Export{" "}
                        </CSVLink>
                      </button>
                    </div>
                  </div>
                  <DatePickerReuse
                    setFilters={setFilter}
                    className="col-md-4"
                  />
                  <SearchInput
                    searchValue={searchValue}
                    setSearchData={setSearchData}
                    setFilter={setFilter}
                  ></SearchInput>
                </div>
              </div>
            </div> */}
            <div class="cmntbl">
              <div class="table-responsive custom-data-table">
                <DataTable
                  columns={columns}
                  data={response && response}
                  customStyles={dataTableConfigs}
                  progressComponent={customLoader}
                  progressPending={loading}
                  persistTableHead={true}
                  noDataComponent={<NoDataComponent />}
                  paginationPerPage={10}
                  paginationRowsPerPageOptions={[5, 10, 20, 30]}
                  pagination
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepositHistory;
