import {
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useMemo, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Tooltip as ReactToolTip } from "react-tooltip";
import { Form, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import Helper from "../../Helper/Helper";
import loginImg from "../../assets/images/kp-new/loginimg.svg";
import metaBig from "../../assets/images/metabig.svg";
import metaSmall from "../../assets/images/metasmall.svg";

import logoImg from "../../assets/images/kp-new/logo.svg";
import OTPmodal from "../../component/OTPmodal";
import metadata from "../../metaData/metadata";
import { useDays, useMonths, useYears } from "./date";
import "./register.scss";
import { useDispatch } from "react-redux";
import { setRegisteMessage } from "./slice";
import OtpInput from "react-otp-input";
import { isObjectHaveKey } from "../../component/utils";
import Countdown from "react-countdown";
const Registerpage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    control,
    resetField,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
  });
  const [isOpen, setIsOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const referralCode = searchParams.get("referid");
  const navigate = useNavigate();
  const [passwordtype, setPasswordtype] = useState("password");
  const [passwordtype1, setPasswordtype1] = useState("password");
  const [loader, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalEmail, setModalEmail] = useState(false);
  const [otp, setOtp] = useState();
  const [country, setCountry] = useState([]);
  const [dialCode, setDialCode] = useState("");
  const [CountryName, setCountryName] = useState("");
  const [code, setcode] = useState();
  const [key, setKey] = useState(0);
  const [registerResponse, setRegisterResponse] = useState({});

  const { days, getDays } = useDays();
  const { months } = useMonths();
  const years = useYears();

  const toggleModal = () => {
    setOtp("");
    setModal(!modal);
  };
  const toggleModalEmail = () => {
    setOtp("");
    setModalEmail(!modalEmail);
    setOtp("");
  };

  const handleEyeChanged = (from) => {
    if (from === "new") {
      setPasswordtype(() => (passwordtype === "text" ? "password" : "text"));
    } else {
      setPasswordtype1(() => (passwordtype1 === "text" ? "password" : "text"));
    }
  };
  useEffect(() => {
    document.body.classList.add("kpstakepage");
  }, []);

  useEffect(() => {
    Helper.GetData("user", "user/countryresp", false).then((res) => {
      setCountry(res.data);
    });
  }, []);

  const onSubmit = (loading) => {
    const data = getValues();
    let otpParam = {
      username: data.user_name,
      phone: dialCode + data.phone_number,
      email: data.email,
      referred_by: data.refferal || referralCode,
      dial_code: dialCode,
    };
    loading && setLoading(true);
    setKey((prev) => prev + 1);
    Helper.PostData("user", "user/otpsent", otpParam, true)
      .then((res) => {
        if (res.status) {
          if (data.dial_code == "+91") {
            toggleModal();
            setRegisterResponse(res.referal_info);
          } else {
            toggleModalEmail();
            setRegisterResponse(res.referal_info);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const dataAvailable = isObjectHaveKey(registerResponse);

  const dispatch = useDispatch();
  const HandleRegister = () => {
    const data = getValues();
    setLoading(true);
    let param = {
      username: data.user_name,
      phone: data.dial_code + data.phone_number,
      dob: data.year + "-" + data.month + "-" + data.day, //yyyy-MM-DD
      email: data.email,
      password: data.password,
      confirm_password: data.confirmpassword,
      referred_by: data.refferal || referralCode,
      country: CountryName,
      country_code: code,
      dial_code: dialCode,
      otp: otp,
    };
    Helper.PostData("user", "user/userregister", param, true)
      .then((res) => {
        if (res.status) {
          dispatch(setRegisteMessage(res.message));
          localStorage.setItem("#wert", res.message);
          window.open("/registerConfirm");
          navigate("/oauth/login");
          setLoading(false);
          if (data.dial_code == "+91") {
            setModal(false);
          } else {
            setModalEmail(false);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeCountry = (val) => {
    let Jparse = JSON.parse(val);
    setcode(Jparse.id);
    setCountryName(Jparse.country_fullname);
    setDialCode(Jparse.dial_code);
    resetField("dial_code");
    setValue("dial_code", Jparse.dial_code);
    setValue("country_name",Jparse.country_fullname)
  };

  const handleChangeDate = (date) => {
    getDays(date);
  };

  const handleOpenTooltip = () => {
    setIsOpen(true);
    setTimeout(() => setIsOpen(false), 7000);
  };

  const Completionist = () => {
    return (
      <div className="text-center mt-2">
        <p className="receive-msg">
          Didn't receive code ?{" "}
          <span type="submit" onClick={() => onSubmit(false)} className="timer">
            Resend
          </span>
        </p>
      </div>
    );
  };

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      const formattedSeconds = String(seconds).padStart(2, '0');
      const formattedMinutes = String(minutes).padStart(2, '0');
  
      return (
        <span style={{ fontSize: "13px", color: "gray" }}>
          Receive OTP in :{" "}
          <p
            style={{
              color: "blue",
              display: "contents",
              fontSize: "12px",
              fontWeight: "400",
            }}
          >
            <span className="text-light">
              {formattedMinutes}:{formattedSeconds}
            </span>
          </p>
        </span>
      );
    }
  };

  const timer = useMemo(
    () => <Countdown date={Date.now() + 60000} renderer={renderer} key={key} />,
    [key]
  );

  return (
    <>
      <Helmet>
        <title>{metadata.register.title}</title>
        <meta name="description" content={metadata.register.description} />
        <meta name="keywords" content={metadata.register.keywords} />
      </Helmet>
      <div className="kp-stake-plateformpage vh-100 logincir">
        <div className="row no-gutters ">
          <div className="col-xl-7 col-lg-6 order-lg-1 order-2" id="fixedDiv">
            <div className="row h-100 no-gutters">
              <div className="offset-xl-2 col-xl-9">
                <div className="loginleft h-100">
                  <div className="d-flex align-items-start flex-column justify-content-around h-100">
                    <div className="logologinDiv d-none d-ld-block d-xl-block">
                      <img
                        alt=""
                        src={metaSmall}
                        className="img-fluid logoLogin"
                        style={{ width: "166px" }}
                      />
                    </div>
                    <div className="logocenter py-5 text-center mx-auto">
                      <img
                        src={metaBig}
                        className="img-fluid logoimg"
                        alt="loginImg"
                      />
                    </div>
                    <div className="row w-100 align-items-center">
                      <div className="col-md-6 text-center text-md-left">
                        {/* <Link
													className='backhomebtn mb-3 mb-lg-0'
													type='button'
													to='/'
												>
													Back to Home{' '}
													<svg
														width='15'
														height='13'
														viewBox='0 0 15 13'
														fill='none'
														xmlns='http://www.w3.org/2000/svg'
													>
														<path
															d='M14.304 2.25171L12.8554 11.3975C12.8333 11.5374 12.7564 11.6628 12.6418 11.7461C12.5272 11.8294 12.3842 11.8637 12.2442 11.8416C12.1043 11.8194 11.9789 11.7425 11.8956 11.6279C11.8123 11.5133 11.778 11.3703 11.8002 11.2303L13.047 3.35794L1.051 12.0736C0.936216 12.1509 0.795886 12.1807 0.659573 12.1567C0.52326 12.1326 0.401608 12.0566 0.320247 11.9446C0.238885 11.8326 0.204167 11.6934 0.223405 11.5563C0.242644 11.4192 0.314337 11.295 0.423382 11.2097L12.4194 2.4941L4.54701 1.24723C4.40707 1.22507 4.28167 1.14822 4.19839 1.0336C4.11511 0.918972 4.08078 0.77596 4.10294 0.636021C4.12511 0.496082 4.20195 0.370679 4.31658 0.287399C4.4312 0.20412 4.57421 0.169786 4.71415 0.19195L13.8599 1.6405C13.9999 1.66266 14.1253 1.73951 14.2085 1.85413C14.2918 1.96876 14.3262 2.11177 14.304 2.25171Z'
															fill='#1140ff'
														/>
													</svg>
												</Link> */}
                      </div>
                      <div className="col-md-6  text-center text-md-right">
                        <div className="fs-14 light-text">
                          &copy; 2023 - KPK PARK - All Rights Reserved.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-5 col-lg-6  order-lg-2 order-1" id="scrollDiv">
            <div className="loginright">
              <div className="row scroller">
                <div className="col-xl-9">
                  <div className="logologinDiv d-lg-none mb-3 text-center">
                    <img
                      alt=""
                      src={metaSmall}
                      className="img-fluid logoLogin"
                      style={{ width: "166px" }}
                    />
                  </div>
                  <form
                    onSubmit={handleSubmit(() => onSubmit(true))}
                    autoComplete="off"
                  >
                    <div className="loginformbox">
                      <div className="loginbx">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="fs-30 fw-500 mb-4">Register</div>
                          <div className="text-right mb-4">
                            {" "}
                            <Link
                              className="text-light"
                              title="Go to Home"
                              to="/"
                            >
                              <FontAwesomeIcon icon={faHome} size={"lg"} />
                            </Link>
                          </div>
                        </div>

                        <div className="form-group formInputs loginInput">
                          <label>
                            Username
                            <span className="text-primary">*</span>
                          </label>
                          <input
                            type="text"
                            name="user_name"
                            className="form-control form-control-lg"
                            placeholder="Username"
                            {...register("user_name", {
                              required: "* Username is required",
                              pattern: {
                                value: /^[a-zA-Z]+$/,
                                message: "* Invalid Username",
                              },
                            })}
                          />
                          {errors.user_name && (
                            <p className="text-danger mt-2">
                              {errors.user_name?.message}
                            </p>
                          )}
                        </div>

                        <div className="form-group formInputs loginInput">
                          <label>
                            Email ID<span className="text-primary">*</span>
                          </label>
                          <input
                            type="email"
                            name="email"
                            autoComplete="username"
                            className="form-control form-control-lg"
                            placeholder="Email ID"
                            {...register("email", {
                              required: "* Email ID is required",
                              pattern: {
                                value: /[^@\s]+@[^@\s]+\.[^@\s]+/,
                                message: "* Invalid Email address",
                              },
                            })}
                          />
                          {errors.email && (
                            <p className="text-danger mt-2">
                              {errors.email?.message}
                            </p>
                          )}
                        </div>
                        <div className="row form-row">
                          <div className="col-md-12">
                            <label>
                              Date of Birth
                              <span className="text-primary">*</span>
                            </label>
                          </div>
                        </div>
                        <div className="row form-row">
                          <div className="col-4">
                            <div className="form-group formInputs loginInput">
                              <select
                                className="form-control form-control-lg"
                                {...register("day", {
                                  required: true,
                                })}
                                // onChange={e => handleChangeDate(e.target.value)}
                              >
                                <option selected value={""} hidden>
                                  Day
                                </option>
                                {days.map((day, index) => (
                                  <>
                                    <option key={index} value={day}>
                                      {day}
                                    </option>
                                  </>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="form-group formInputs loginInput">
                              <select
                                className="form-control form-control-lg"
                                {...register("month", {
                                  required: true,
                                })}
                                onChange={({ target: { value } }) =>
                                  handleChangeDate({
                                    choosed_month: value,
                                    choosed_year: getValues().year,
                                  })
                                }
                              >
                                <option selected value={""} hidden>
                                  Month
                                </option>
                                {months.map((month, index) => {
                                  let ind = index + 1;
                                  return (
                                    <>
                                      <option
                                        key={index}
                                        value={
                                          String(ind).length > 1
                                            ? ind
                                            : "0" + ind
                                        }
                                      >
                                        {month}
                                      </option>
                                    </>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="form-group formInputs loginInput">
                              <select
                                className="form-control form-control-lg"
                                {...register("year", {
                                  required: true,
                                })}
                                onChange={({ target: { value } }) =>
                                  handleChangeDate({
                                    choosed_year: value,
                                    choosed_month: getValues().month || "01",
                                  })
                                }
                              >
                                <option selected value={""} hidden>
                                  Year
                                </option>
                                {years.map((year, index) => (
                                  <>
                                    <option key={index} value={year}>
                                      {year}
                                    </option>
                                  </>
                                ))}
                              </select>
                            </div>
                          </div>
                          {(errors.day || errors.month || errors.year) && (
                            <p className="text-danger">
                              * Date of Birth is required
                            </p>
                          )}
                        </div>
                        <div className="row form-row">
                          <div className="col-md-12">
                            <div className="form-group formInputs loginInput">
                              <label>
                                Country<span className="text-primary">*</span>
                              </label>
                              <select
                                className="form-control form-control-lg"
                                {...register("country_name", {
                                  required: true,
                                })}
                                onChange={(e) =>
                                  handleChangeCountry(e.target.value)
                                }
                              >
                                <option selected value={""} hidden>
                                  Select Country
                                </option>
                                {country?.map((item, index) => (
                                  <>
                                    <option value={JSON.stringify(item)}  selected={item.country_fullname == CountryName}>
                                      {item.country_fullname}
                                    </option>
                                  </>
                                ))}
                              </select>
                              {errors.country_name && (
                                <p className="text-danger mt-2">
                                  * Country is required
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row form-row">
                          <div className="col-md-12">
                            <div className="form-group formInputs loginInput">
                              <label>
                                Phone Number
                                <span className="text-primary">*</span>
                              </label>
                              <div className="row form-row">
                                <div className="col-4">
                                  <select
                                    {...register("dial_code", {
                                      required: true,
                                    })}
                                    onChange={(e) =>
                                      handleChangeCountry(e.target.value)
                                    }
                                    className="form-control form-control-lg"
                                  >
                                    <option selected value={""} hidden>
                                      Select Dial Code
                                    </option>
                                    {country?.map((item, index) => (
                                      <>
                                        <option
                                          value={JSON.stringify(item)}
                                          selected={item.dial_code == dialCode}
                                          key={index}
                                        >
                                          {item.dial_code}
                                        </option>
                                      </>
                                    ))}
                                  </select>
                                  {errors.dial_code && (
                                    <p className="text-danger mt-2">
                                      * Dial Code is required
                                    </p>
                                  )}
                                </div>
                                <div className="col-8">
                                  <input
                                    type="text"
                                    name="phone_number"
                                    maxLength={18}
                                    inputMode="numeric"
                                    className="form-control form-control-lg"
                                    placeholder="Phone Number"
                                    {...register("phone_number", {
                                      required: "* Phone Number is required",
                                      pattern: {
                                        value: /^\d*$/,
                                        message:
                                          "* Must number should allowed ",
                                      },
                                      maxLength: {
                                        value: 18,
                                        message: "* Maximum within 18 Digits",
                                      },
                                    })}
                                  />
                                  {errors.phone_number && (
                                    <p className="text-danger mt-2">
                                      {errors.phone_number?.message}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row form-row">
                          <div className="col-md-12">
                            <div className="form-group formInputs loginInput">
                              <label>
                                Password
                                <span className="text-primary">*</span>
                                <FontAwesomeIcon
                                  icon={faExclamationCircle}
                                  data-tooltip-variant="info"
                                  onMouseEnter={() => setIsOpen(true)}
                                  onMouseLeave={() => setIsOpen(false)}
                                  data-tooltip-id="password-tooltip"
                                  size="sm"
                                  color="gray"
                                  className="ml-1 cursor-pointer"
                                />
                              </label>
                              <div className="input-group mb-3">
                                <input
                                  type={passwordtype}
                                  className="form-control form-control-lg"
                                  placeholder="Enter Password"
                                  onFocus={() => handleOpenTooltip()}
                                  {...register("password", {
                                    required: "* Password is required",
                                    minLength: {
                                      value: 8,
                                      // message'* Minimum length must be 8 characters'

                                      message: "* Invalid Password format",
                                    },
                                    // maxLength: {
                                    // 	value: 16,
                                    // 	// message: '* Maximum within 16 characters'
                                    // 	message: '* Invalid Password format'
                                    // },
                                    pattern: {
                                      value:
                                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                                      message: "* Invalid Password format",
                                      // '* Password must be at least 8 characters long and contain a combination of uppercase letters, lowercase letters, numbers, and special characters'
                                    },
                                  })}
                                />

                                <div className="input-group-append">
                                  <span
                                    className="input-group-text cur-poin"
                                    onClick={() => handleEyeChanged("new")}
                                    id="basic-addon2"
                                  >
                                    {passwordtype === "password" ? (
                                      <FontAwesomeIcon icon={faEyeSlash} />
                                    ) : (
                                      <FontAwesomeIcon icon={faEye} />
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                            {errors.password && (
                              <p className="text-danger">
                                {errors.password?.message}
                              </p>
                            )}
                          </div>

                          <div className="col-md-12">
                            <div className="form-group formInputs loginInput">
                              <label>
                                Confirm Password
                                <span className="text-primary">*</span>
                              </label>
                              <div className="input-group mb-3">
                                <input
                                  type={passwordtype1}
                                  className="form-control form-control-lg"
                                  placeholder="Confirm Password"
                                  {...register("confirmpassword", {
                                    required: "* Confirm Password is required",
                                    validate: {
                                      matchesPreviousPassword: (value) => {
                                        const { password } = getValues();
                                        return (
                                          password === value ||
                                          "* Passwords did not match!"
                                        );
                                      },
                                    },
                                  })}
                                />
                                <div className="input-group-append">
                                  <span
                                    className="input-group-text cur-poin"
                                    onClick={() => handleEyeChanged("confirm")}
                                    id="basic-addon2"
                                  >
                                    {passwordtype1 === "password" ? (
                                      <FontAwesomeIcon icon={faEyeSlash} />
                                    ) : (
                                      <FontAwesomeIcon icon={faEye} />
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                            {errors.confirmpassword && (
                              <p className="text-danger">
                                {errors.confirmpassword?.message}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="row form-row">
                          <div className="col-md-12">
                            <div className="form-group formInputs loginInput">
                              <label>
                                Referral Id
                                <span className="text-primary">*</span>
                              </label>
                              {referralCode ? (
                                <input
                                  type="text"
                                  name="refferal"
                                  value={referralCode}
                                  className="form-control form-control-lg"
                                  placeholder="Referral Id"
                                  disabled={!!referralCode}
                                />
                              ) : (
                                <input
                                  type="text"
                                  name="refferal"
                                  className="form-control form-control-lg"
                                  placeholder="Referral Id"
                                  {...register("refferal", {
                                    required: "* Referral Id is required",
                                  })}
                                />
                              )}
                            </div>
                            {errors.refferal && (
                              <p className="text-danger">
                                {errors.refferal?.message}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <div className="custom-control custom-checkbox custom_checkbox logincheckbox">
                              <input
                                type="checkbox"
                                id="customCheck"
                                name="example1"
                                className="custom-control-input"
                                {...register("example1", {
                                  required:
                                    "* Please check Terms and Conditions",
                                })}
                              />
                              <label
                                for="customCheck"
                                className="custom-control-label light-text"
                              >
                                {" "}
                                By checking the box you will accept our &nbsp;
                                <a
                                  href="/terms"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-primary"
                                >
                                  Terms &amp; Conditions
                                </a>
                              </label>
                              {errors.example1 && (
                                <p className="text-danger">
                                  {errors.example1.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="mt-3 mb-2">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block fs-18 fw-500"
                            disabled={loader}
                          >
                            {loader ? (
                              <Spinner size="sm" />
                            ) : (
                              "Create an Account"
                            )}
                          </button>
                        </div>
                      </div>
                      <div className="text-center light-text mb-4 ml-auto order-1 order-md-2">
                        {" "}
                        Already have an account?
                        <Link className="text-primary ml-2" to="/oauth/login">
                          Login
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OTPmodal
        otp={otp}
        setOtp={setOtp}
        modal={modal}
        toggleModal={toggleModal}
        loader={loader}
        handleApiCall={HandleRegister}
        resendOTP={onSubmit}
        keys={key}
        sentTo={Helper.formatPhone(
          getValues().dial_code + getValues().phone_number
        )}
        data={registerResponse}
      />
      <ReactToolTip id="password-tooltip" isOpen={isOpen}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>
            Eg : <b>Example@123</b>
          </span>
          <span>
            {" "}
            1. Password must be at least 8 characters long <br /> 2. Contain a
            combination of uppercase letters,
            <br /> lowercase letters, numbers, and special characters
          </span>
        </div>
      </ReactToolTip>
      <Modal
        className="ver_pop"
        size="sm"
        isOpen={modalEmail}
        toggleModal={toggleModalEmail}
      >
        <ModalHeader toggle={toggleModalEmail}>
          <h5 className="text-center text-light">OTP Email Verification</h5>
        </ModalHeader>
        <ModalBody>
          {dataAvailable && (
            <>
              <p>
                Kindly Confirm Referral Person Name Before Submit Email OTP.
              </p>

              <div className="d-flex justify-content-between">
                <p className="mt-2 text-light">Referred by</p>
                <p className="mt-2" style={{ color: "#ffb721" }}>
                  <b>{registerResponse.referal_name}</b>
                </p>
              </div>
            </>
          )}
          <div className="d-flex justify-content-between">
            <p className=" text-light">OTP Sent To</p>
            <p className=" text-light">
              <b>{Helper.formatPhone(getValues().email)}</b>
            </p>
          </div>

          <div className="otp">
            <label className="mt-1 text-light">Enter the 6 digit code</label>
            <Form onSubmit={handleSubmit(HandleRegister)}>
              <div className="mt-2">
                <OtpInput
                  value={otp}
                  onChange={(otp) => {
                    setOtp(otp);
                  }}
                  numInputs={6}
                  inputStyle="form-control"
                  containerStyle="cdChIpR cdChIpR1"
                  className="form-group"
                  isInputNum={true}
                />
              </div>

              <div className="mt-3 text-center">
                {otp && otp.length == 6 ? (
                  <button
                    className="btn btn-primary btn-block fs-18 fw-500"
                    disabled={loader}
                  >
                    {loader ? <Spinner size="sm" /> : "Submit"}
                  </button>
                ) : (
                  <button
                    className="btn btn-primary btn-block fs-18 fw-500"
                    disabled
                  >
                    {loader ? <Spinner size="sm" /> : "Submit"}
                  </button>
                )}
              </div>

              <div className="text-center  mt-1">{timer}</div>
            </Form>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Registerpage;
