import React, { useEffect, useState } from 'react'
import searchicon from '../../assets/images/search-icon.svg'
import DataTable from 'react-data-table-component'
import moment from 'moment'
import Helper from '../../Helper/Helper'
import { dataTableConfigs } from '../../configs/dataTableConfigs'
import { TableLoader } from '../../component/Loaders'
import NoDataComponent from '../../component/Reusable/no-data-component'
import '../Incubator/incubator.scss'
const IncubatorHistory = () => {
	const initialPageData = { pageNo: 0, pageSize: 10 }

	const [incubatorData, setIncubatorHistory] = useState({
		data: [],
		count: 0
	})
	const [Loading, setLoading] = useState(false)
	const [filters, setFilter] = useState({
		user_id: localStorage.getItem('tip'),
		currency_symbol: 'all',
		search_by: 'all',
		status: 'all',
		start_date: '',
		end_date: '',
		...initialPageData,
		search: '',
		sort: 'Oldest'
	})

	useEffect(() => {
		getStateHistory()
	}, [filters])

	const getStateHistory = () => {
		setLoading(true)
		Helper.PostData(
			'wallet',
			'incubator/plan_subscribe_history',
			filters,
			false
		)
			.then(res => {
				if (res.status) {
					setIncubatorHistory({
						data: res.data.total_results,
						count: res.data.total_rows
					})
				}
			})
			.finally(() => {
				setLoading(false)
			})
	}
	//S.no	Date of Subscription	Amount	Currency	Percentage %	Total Plots	Sold Plots	Remaining Plots	Plots ROI

	const columns = [
		{
			name: 'S.No',
			sortable: true,
			selector: (row, index) => row.index,
			cell: (row, index) => <td>{index + 1}</td>
		},

		{
			name: 'Amount',
			selector: row => row.purchase_amount,
			sortable: true
		},
		{
			name: 'Currency',
			selector: row => row.currency_symbol,
			sortable: true
		},
		{
			name: 'Percentage %',
			selector: row => row.percentage,
			sortable: true
		},
		{
			name: 'Total Plots',
			sortable: true,
			selector: row => row.total_plots
		},
		{
			name: 'Sold Plots',
			sortable: true,
			selector: row => row.sold_plots
		},
		{
			name: '	Remaining Plots',
			selector: row => row.remaining_plots,
			sortable: true
		},
		{
			name: 'Plots ROI',
			sortable: true,
			selector: row => row.plots_roi
		},
		{
			name: 'Total ROI',
			sortable: true,
			selector: row => row.total_roi
		},

		{
			name: 'Status',
			sortable: true,
			selector: row => row.status,
			cell: row => {
				if (row.status == 0) {
					return <div className=' badge text-warn'>Pending</div>
				} else if (row.status == 1) {
					return <div className='badge bg-success'>Subscribed</div>
				} else if (row.status == 2) {
					return <div className='badge bg-danger'>Rejected</div>
				}
			}
		},
		{
			name: 'Date of Subscription',
			sortable: true,
			selector: (row, index) => row.created_at,

			cell: (row, index) => (
				<td >
					{moment(row.created_at).format('YYYY-MM-DD, hh:mm:ss A')}
				</td>
			)
		}
	]
	return (
		<div>
			<div className='trans-body'>
				<div className='trans-TabCnt'>
					<div className='stakingbonus-tabcnt'>
						<div className='bonus-Head'>
							<h5>Incubator History</h5>
						</div>
						<div className='bonus-Body'>
							{/* <div className='tbl-showentriessearch'>
								<div className='showentries-RightCnt'>
									<div className='inr-searchcnt'>
										<div>
											<p>Sort by</p>
										</div>
										<div className='mx-2 my-2'>
											<select name='' id=''>
												<option value=''>Recently</option>
												<option value=''>Recently</option>
												<option value=''>Recently</option>
											</select>
										</div>
										<div className='search-input'>
											<input type='text' placeholder='Search' />
											<span>
												<img src={searchicon} className='img-fluid' alt='' />
											</span>
										</div>
									</div>
								</div>
							</div> */}
							<div className='cmntbl'>
								<div className='table-responsive'>
									<DataTable
										columns={columns}
										className='text-center'
										data={incubatorData.data}
										paginationTotalRows={incubatorData.count}
										customStyles={dataTableConfigs}
										pagination={false}
										paginationServer
										onChangePage={pageNo => {
											setFilter(prev => ({ ...prev, pageNo: pageNo - 1 }))
										}}
										progressPending={Loading}
										progressComponent={<TableLoader />}
										noDataComponent={<NoDataComponent />}
										onChangeRowsPerPage={pageSize => {
											setFilter(prev => ({ ...prev, pageSize }))
										}}
										persistTableHead={true}
										// paginationPerPage={10}
										paginationRowsPerPageOptions={[5, 10, 20, 30]}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default IncubatorHistory
