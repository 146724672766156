import { faClock, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Helper from "../../Helper/Helper";
import cancel from "../../assets/images/cancel.webp";
import closeIcon from "../../assets/images/close-x.svg";
import { SpinnerLoader, TableLoader } from "../../component/Loaders";
import CloseBtn from "../../component/Reusable/CloseBtn";
import CopyData2 from "../../component/Reusable/CopyData 2";
import NoDataComponent from "../../component/Reusable/no-data-component";
import { dataTableConfigs } from "../../configs/dataTableConfigs";
import "../Incubator/incubator.scss";

const InvestorHistory = ({ IsTabSwitch }) => {
  const initialPageData = { pageNo: 0, pageSize: 10 };
  const [incubatorData, setIncubatorHistory] = useState({
    data: [],
    count: 0,
  });
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const fromMinDate = () => {
    let date = new Date();
    return date;
  };
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    register,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
  });
  const {
    handleSubmit: handleSubmitEmail,
    formState: { errors: errorsEmail },
    control: controlEmail,
    reset: resetEmail,
    register: registerEmail,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
  });
  const {
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
    control: control1,
    reset: reset1,
    register: register1,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
  });
  const [type, setType] = useState("1");
  const [BtnLoading, setBtnLoading] = useState({
    rejLoading: false,
    updLoading: false,
    reAdLoading: false,
    emUpdte: false,
  });
  const [isRejOpen, setIsRejOpen] = useState(false);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const toggleUpdate = () => {
    setIsUpdateOpen(!isUpdateOpen);
  };
  const toggleReject = (type) => {
    if (type == "reject") {
      setIsRejOpen(!isRejOpen);
    } else {
      setIsAddOpen(!isAddOpen);
    }
  };
  const [Loading, setLoading] = useState(false);
  const [filters, setFilter] = useState({
    user_id: localStorage.getItem("tip"),
    search_by: "",
    status: "",
    ...initialPageData,
  });

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenEmailUpdate, setIsOpenEmailUpdate] = useState(false);
  const [rowInfo, setRowInfo] = useState(false);
  const [EmailIdInfo, setEmailIdInfo] = useState("");
  const toggleEmailUpdate = (row) => {
    setIsOpenEmailUpdate(!isOpenEmailUpdate);
    setEmailIdInfo(row.id);
  };
  const toggle = (row) => {
    setIsOpen(!isOpen);
    setRowInfo(row);
  };
  const [isMeet, setIsMeet] = useState(false);
  const toggleMeet = (row) => {
    if (row.email == null || row.email == "") {
      toggleEmailUpdate(row);
    } else {
      setRowInfo(row);
      setIsMeet(!isMeet);
      reset1();
    }
  };
  const toggleMeetclose = () => {
    setMeetLoad(false);
    setIsMeet(false);
    reset1();
  };
  const closeBtn = (
    <button
      type="button"
      className="close"
      aria-label="Close"
      onClick={() => setIsOpen(false)}
    >
      <img src={closeIcon} alt="close-x" />
    </button>
  );
  useEffect(() => {
    getStateHistory();
  }, [filters]);

  const getStateHistory = () => {
    setLoading(true);
    Helper.PostData("inves", "crm/investorsList", filters, false)
      .then((res) => {
        if (res.status) {
          setIncubatorHistory({
            data: res.data.results,
            count: res.count,
          });
        } else {
          setIncubatorHistory({
            data: [],
            count: 0,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const HandleReject = (id, type) => {
    if (type == "reject") {
      setBtnLoading({
        rejLoading: true,
        updLoading: false,
        reAdLoading: false,
        emUpdte: false,
      });
      Helper.PostData(
        "inves",
        "crm/rejectInvestor",
        { userId: localStorage.getItem("tip"), id },
        true
      )
        .then((res) => {
          if (res.status) {
            getStateHistory();
            setIsRejOpen(false);
            setIsOpen(false);
            setBtnLoading({
              rejLoading: false,
              updLoading: false,
              reAdLoading: false,
              emUpdte: false,
            });
          }
        })
        .finally(() => {
          setBtnLoading({
            rejLoading: false,
            updLoading: false,
            reAdLoading: false,
            emUpdte: false,
          });
        });
    } else {
      setBtnLoading({
        rejLoading: false,
        updLoading: false,
        reAdLoading: true,
        emUpdte: false,
      });
      Helper.PostData(
        "inves",
        "crm/reAddInvestor",
        { userId: localStorage.getItem("tip"), id },
        true
      )
        .then((res) => {
          if (res.status) {
            getStateHistory();
            setIsAddOpen(false);
            setIsOpen(false);
            setBtnLoading({
              rejLoading: false,
              updLoading: false,
              reAdLoading: false,
              emUpdte: false,
            });
          }
        })
        .finally(() => {
          setBtnLoading({
            rejLoading: false,
            updLoading: false,
            reAdLoading: false,
            emUpdte: false,
          });
        });
    }
  };
  const HandleUpdate = (data) => {
    setBtnLoading({
      rejLoading: false,
      reAdLoading: false,
      updLoading: true,
      emUpdte: false,
    });
    Helper.PostData(
      "inves",
      "crm/updateConvert",
      {
        userId: localStorage.getItem("tip"),
        id: rowInfo.id,
        ...data,
      },
      true
    )
      .then((res) => {
        if (res.status) {
          reset();
          setBtnLoading({
            rejLoading: false,
            reAdLoading: false,
            updLoading: false,
            emUpdte: false,
          });
          setIsUpdateOpen(false);
          setIsOpen(false);
          getStateHistory();
        }
      })
      .finally(() => {
        setBtnLoading({
          rejLoading: false,
          reAdLoading: false,
          updLoading: false,
          emUpdte: false,
        });
      });
  };
  const columns = [
    {
      name: "S.No",
      sortable: true,
      selector: (row, index) => row.index,
      cell: (row, index) => <td>{index + 1}</td>,
    },

    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      cell: (row, index) => (
        <td>
          <CopyData2 data={row.email} />
        </td>
      ),
    },
    {
      name: "Investor Type",
      selector: (row) => row.investor_type,
      cell:(row,index)=>{
        return <td style={{textTransform:"capitalize"}}>{row.investor_type}</td>
      },
      sortable: true,
    },

    {
      name: "Status",
      sortable: true,
      selector: (row) => row.status,
      cell: (row) => {
        if (row.status == 1) {
          return <div className="badge bg-info">Discussion</div>;
        } else if (row.status == 2) {
          return <div className="badge bg-success">Converted</div>;
        } else {
          return <div className=" badge bg-danger">Declined</div>;
        }
      },
    },
    {
      name: "Date/Time",
      sortable: true,
      selector: (row, index) => row.created_at,

      cell: (row, index) => (
        <td>{moment(row.created_at).format("YYYY-MM-DD hh:mm:ss A")}</td>
      ),
    },
    {
      name: "Schedule Meeting",
      sortable: false,
      cell: (row, index) => (
        <td>
          <FontAwesomeIcon
            icon={faClock}
            className="cursor-pointer"
            onClick={() => toggleMeet(row)}
          />
        </td>
      ),
    },
    {
      name: "Action",
      sortable: false,
      cell: (row, index) => (
        <td>
          <FontAwesomeIcon
            icon={faEye}
            className="cursor-pointer"
            onClick={() => toggle(row)}
          />
        </td>
      ),
    },
  ];
  const [meetLoad, setMeetLoad] = useState(false);
  const HandleMeetSchedule = (data) => {
    setMeetLoad(true);
    Helper.PostData("inves", "crm/scheduleMeet", {
      user_id: localStorage.getItem("tip"),
      mail_id: rowInfo.email,
      type: data.type,
      start_date_time: moment(data.start_date).format("YYYY-MM-DD HH:mm:ss"),
      location: data.location,
      address: data.address,
      remarks: data.remarks,
    })
      .then((res) => {
        if (res.status) {
          setIsMeet(!isMeet);
          setMeetLoad(false);
          getStateHistory();
          IsTabSwitch();
        }
      })
      .finally(() => {
        setMeetLoad(false);
      });
  };

  const HandleEmailUpdate = (data) => {
    setBtnLoading((prev) => ({
      ...prev,
      emUpdte: true,
    }));
    Helper.PostData("inves", "crm/updateMail", {
      id: EmailIdInfo,
      userId: localStorage.getItem("tip"),
      mail_id: data.email,
    })
      .then((Res) => {
        if (Res.status) {
          setBtnLoading((prev) => ({
            ...prev,
            emUpdte: false,
          }));
          setIsOpenEmailUpdate(false);
          getStateHistory();
        }
      })
      .finally(() => {
        setBtnLoading((prev) => ({
          ...prev,
          emUpdte: false,
        }));
      });
  };
  return (
    <Fragment>
      <div>
        <div className="trans-body">
          <div className="trans-TabCnt">
            <div className="stakingbonus-tabcnt">
              <div className="bonus-Head">
                <h5>Investor History</h5>
              </div>
              <div className="bonus-Body">
                <div className="tbl-showentriessearch jus_start">
                  <div className="showentries-RightCnt">
                    <div className="inr-searchcnt">
                      <div className="row">
                        <div className="col-lg-12">
                          <select
                            className="form-control"
                            onChange={(e) =>
                              setFilter((prev) => ({
                                ...prev,
                                status: e.target.value,
                              }))
                            }
                          >
                            <option value="all">All</option>
                            <option value="converted">Converted</option>
                            <option value="discussion">Discussion</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cmntbl">
                  <div className="table-responsive">
                    <DataTable
                      columns={columns}
                      className="text-center"
                      data={incubatorData.data}
                      paginationTotalRows={incubatorData.count}
                      customStyles={dataTableConfigs}
                      pagination={false}
                      paginationServer
                      onChangePage={(pageNo) => {
                        setFilter((prev) => ({ ...prev, pageNo: pageNo - 1 }));
                      }}
                      progressPending={Loading}
                      progressComponent={<TableLoader />}
                      noDataComponent={<NoDataComponent />}
                      onChangeRowsPerPage={(pageSize) => {
                        setFilter((prev) => ({ ...prev, pageSize }));
                      }}
                      persistTableHead={true}
                      // paginationPerPage={10}
                      paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="modal-md modal-dialog-centered mymodal"
        size="md"
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader toggle={toggle} close={closeBtn}>
          Investor Details
        </ModalHeader>
        <ModalBody className="px-4">
          <div className="row">
            <div className="col-12">
              <div className="teble-responsive">
                <div className="in-info">
                  <p>Name</p>
                  <span>{rowInfo.name}</span>
                </div>
                <div className="in-info">
                  <p>Email</p>
                  <span>{rowInfo.email}</span>
                </div>
                <div className="in-info">
                  <p>Type</p>
                  <span>{rowInfo.investor_type}</span>
                </div>
                <div className="in-info">
                  <p>Investor Status</p>
                  {(() => {
                    if (+rowInfo.status == 2) {
                      return <span className="text-success">Converted</span>;
                    } else if (+rowInfo.status == 1) {
                      return <span className="text-info">Discussion</span>;
                    } else {
                      return <span className="text-danger">Declined</span>;
                    }
                  })()}
                </div>
                <div className="in-info">
                  <p>Date/Time</p>
                  <span>
                    {moment(rowInfo.created_at).format("YYYY-MM-DD hh:mm:ss")}
                  </span>
                </div>
                {(() => {
                  if (+rowInfo.status == 2) {
                    return "";
                  } else if (+rowInfo.status == 1) {
                    return (
                      <div className="in-btn-info mt-3">
                        <button
                          className="btn btn-success w-100"
                          onClick={() => toggleUpdate()}
                        >
                          Convert
                        </button>
                        <button
                          className="btn btn-danger w-100"
                          onClick={() => toggleReject("reject")}
                        >
                          Decline
                        </button>
                      </div>
                    );
                  } else {
                    return (
                      <div className="in-btn-info mt-3">
                        <button
                          className="btn btn-secondary w-100"
                          onClick={() => toggleReject("readd")}
                        >
                          Move to Discussion
                        </button>
                      </div>
                    );
                  }
                })()}
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={isRejOpen}
        toggle={() => toggleReject("reject")}
        className="modal-md modal-dialog-centered mymodal"
        size="sm"
        backdrop="static"
        keyboard={false}
      >
        <ModalBody>
          <div className="text-center mb-2">
            <img src={cancel} width={"50%"} />
          </div>
          <p className="text-center">
            <b>Do you want to Decline ?</b>
            <br />
          </p>
          <div className="d-flex justify-content-between mt-3">
            <div>
              {BtnLoading.rejLoading ? (
                <button
                  type="button"
                  class="btn-exg-del px-4"
                  disabled={BtnLoading.rejLoading}
                >
                  <SpinnerLoader />
                </button>
              ) : (
                <button
                  type="button"
                  class="btn-exg-del px-4"
                  onClick={() => HandleReject(rowInfo.id, "reject")}
                >
                  Confirm
                </button>
              )}
            </div>
            <div>
              <button
                type="button"
                class="btn-exg  px-4"
                onClick={() => toggleReject("reject")}
              >
                Cancel
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={isAddOpen}
        toggle={() => toggleReject("readd")}
        className="modal-md modal-dialog-centered mymodal"
        size="sm"
        backdrop="static"
        keyboard={false}
      >
        <ModalBody>
          <div className="text-center mb-2">
            <img src={cancel} width={"50%"} />
          </div>
          <p className="text-center">
            <b>Do you want to Add Investor ?</b>
            <br />
          </p>
          <div className="d-flex justify-content-between mt-3">
            <div>
              {BtnLoading.reAdLoading ? (
                <button
                  type="button"
                  class="btn-exg-del px-4"
                  disabled={BtnLoading.reAdLoading}
                >
                  <SpinnerLoader />
                </button>
              ) : (
                <button
                  type="button"
                  class="btn-exg-del px-4"
                  onClick={() => HandleReject(rowInfo.id, "readd")}
                >
                  Confirm
                </button>
              )}
            </div>
            <div>
              <button
                type="button"
                class="btn-exg  px-4"
                onClick={() => toggleReject("readd")}
              >
                Cancel
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={isUpdateOpen}
        toggle={toggleUpdate}
        className="modal-md modal-dialog-centered mymodal"
        size="sm"
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader
          toggle={toggle}
          close={
            <CloseBtn
              onClick={() => {
                setIsUpdateOpen(!isUpdateOpen);
                reset();
              }}
            />
          }
        >
          Update Details
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(HandleUpdate)} autoComplete="off">
            <div class="row">
              <div class="col-12">
                <div class="form-group formInputs walletInput">
                  <label class="col-form-label">Stake Id</label>
                  <input
                    class="form-control"
                    name="stakeId"
                    placeholder="Enter Stake Id"
                    {...register("stakeId", {
                      required: "* Stake Id required",
                    })}
                  />
                  {errors.stakeId && (
                    <p className="text-danger">{errors.stakeId.message}</p>
                  )}
                </div>
                <div class="form-group formInputs walletInput">
                  <label class="col-form-label">Amount</label>
                  <input
                    class="form-control"
                    name="amount"
                    placeholder="Enter Amount"
                    {...register("amount", {
                      required: "* Amount is required",
                      validate: (value) => {
                        if (+value == 0) {
                          return "* Amount is greater than 0";
                        }
                      },
                    })}
                  />
                  {errors.amount && (
                    <p className="text-danger">{errors.amount.message}</p>
                  )}
                </div>
              </div>
            </div>
            <div class="text-center mb-2">
              {BtnLoading.updLoading ? (
                <button
                  class="btn btn-primary px-4"
                  type="button"
                  disabled={BtnLoading.updLoading}
                >
                  <SpinnerLoader />
                </button>
              ) : (
                <button class="btn btn-primary px-4" type="submit">
                  Confirm
                </button>
              )}
            </div>
          </form>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={isMeet}
        toggle={toggleMeet}
        className="modal-md modal-dialog-centered mymodal"
        size="md"
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader
          toggle={toggleMeetclose}
          close={<CloseBtn onClick={() => toggleMeetclose()} />}
        >
          Meeting Schedule
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit1(HandleMeetSchedule)} autoComplete="off">
            <div class="row">
              <div className="col-12">
                <div class="form-group formInputs walletInput">
                  <label class="col-form-label">Email</label>
                  <input
                    class="form-control"
                    name="email"
                    placeholder="Email Address"
                    defaultValue={rowInfo?.email}
                    disabled={true}
                  />
                </div>

                <div class="wallet-in-ser formInputs walletInput">
                  <label>Meeting Time</label>
                  <div class="input-login-1  ml-lg-2">
                    <Controller
                      name="start_date"
                      control={control1}
                      rules={{ required: true }}
                      render={({ field: { onChange, onBlur, value } }) => {
                        const selectedStartDate = value
                          ? new Date(value)
                          : null;

                        return (
                          <DatePicker
                            className="form-control"
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={selectedStartDate}
                            minDate={fromMinDate()}
                            dropdownMode="select"
                            peekNextMonth
                            showTimeSelect={true}
                            dateFormat="dd/MM/yyyy HH:mm:ss"
                            placeholderText="DD/MM/YYYY HH:MM:SS"
                          />
                        );
                      }}
                    />
                    {errors1.start_date && (
                      <p>
                        {errors1.start_date.type === "required" ? (
                          <p className="text-danger">
                            * Start Date is required
                          </p>
                        ) : (
                          <p className="text-danger">
                            {errors1.start_date.message}
                          </p>
                        )}
                      </p>
                    )}
                  </div>
                </div>
                <div class="form-group formInputs walletInput">
                  <label class="col-form-label">Type</label>
                  <select
                    class="form-control"
                    name="type"
                    {...register1("type", {
                      required: "* Type is required",
                    })}
                    onChange={({ target: { value } }) => {
                      setType(value);
                    }}
                  >
                    <option hidden value="">
                      Select Type
                    </option>
                    <option value={1}>Online</option>
                    <option value={0}>Offline</option>
                  </select>
                  {errors1.type && (
                    <p className="text-danger">{errors1.type.message}</p>
                  )}
                </div>
                {+type == 0 && (
                  <>
                    <div class="form-group formInputs walletInput">
                      <label class="col-form-label">Location</label>
                      <input
                        class="form-control"
                        name="location"
                        placeholder="Enter Location"
                        {...register1("location", {
                          required: "* Location is required",
                        })}
                      />
                      {errors1.location && (
                        <p className="text-danger">
                          {errors1.location.message}
                        </p>
                      )}
                    </div>
                    <div class="form-group formInputs walletInput">
                      <label class="col-form-label">Address</label>
                      <input
                        class="form-control"
                        name="address"
                        placeholder="Enter Address"
                        {...register1("address", {
                          required: "* Address is required",
                        })}
                      />
                      {errors1.address && (
                        <p className="text-danger">{errors1.address.message}</p>
                      )}
                    </div>
                  </>
                )}
                <div class="form-group formInputs walletInput">
                  <label class="col-form-label">Remark</label>
                  <textarea
                    class="form-control"
                    name="remarks"
                    placeholder="Enter Remarks"
                    {...register1("remarks", {
                      required: "* Remark is required",
                    })}
                  />
                  {errors1.remarks && (
                    <p className="text-danger">{errors1.remarks.message}</p>
                  )}
                </div>
              </div>
            </div>
            <div class="text-center mb-2 mt-3">
              {meetLoad ? (
                <button
                  class="btn btn-primary px-4"
                  type="button"
                  disabled={meetLoad}
                >
                  <SpinnerLoader />
                </button>
              ) : (
                <button class="btn btn-primary px-4" type="submit">
                  Confirm
                </button>
              )}
            </div>
          </form>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={isOpenEmailUpdate}
        toggle={toggleEmailUpdate}
        className="modal-md modal-dialog-centered mymodal"
        size="sm"
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader
          toggle={toggleEmailUpdate}
          close={
            <CloseBtn
              onClick={() => {
                setIsOpenEmailUpdate(!isOpenEmailUpdate);
                resetEmail();
              }}
            />
          }
        >
          Update Email Address
        </ModalHeader>
        <ModalBody>
          <form
            onSubmit={handleSubmitEmail(HandleEmailUpdate)}
            autoComplete="off"
          >
            <div class="row">
              <div class="col-12">
                <div class="form-group formInputs walletInput">
                  <label class="col-form-label">Email</label>
                  <input
                    class="form-control"
                    name="email"
                    type="email"
                    placeholder="Enter Email"
                    {...registerEmail("email", {
                      required: "* Email is required",
                    })}
                  />
                  {errorsEmail.email && (
                    <p className="text-danger">{errorsEmail.email.message}</p>
                  )}
                </div>
                <div className="noted-email mt-2">
                  <p className="text-danger mb-2">Note *</p>
                  <span className="text-danger fs-13">
                    Update your email address and proceed to schedule a meeting.
                  </span>
                </div>
              </div>
            </div>
            <div class="text-center mt-2">
              {BtnLoading.emUpdte ? (
                <button
                  class="btn btn-primary px-4"
                  type="button"
                  disabled={BtnLoading.emUpdte}
                >
                  <SpinnerLoader />
                </button>
              ) : (
                <button class="btn btn-primary px-4" type="submit">
                  Confirm
                </button>
              )}
            </div>
          </form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default InvestorHistory;
